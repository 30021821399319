@if ({ isSmallViewport: isSmallViewport$ | async }; as obj) {
  <div
    class="list-container"
    [class.layout-full]="obj.isSmallViewport"
    [class.navigated]="obj.isSmallViewport && route.children.length > 0"
  >
    <div class="title-bar">
      <div class="title">
        {{ moduleTitle }}

        @if (resultCount != null) {
          <span class="title__count"> ({{ resultCount | number }}) </span>
        }

        <ng-content select="[title-additional-content]" />
      </div>

      <ng-content select="[title-additional-controls]" />

      <button mat-icon-button color="accent" aria-label="Toggle Search" (click)="toggleSearch()">
        <mat-icon>search</mat-icon>
      </button>

      @if (contextMenuButtonVisible) {
        <button mat-icon-button color="accent" aria-label="More Actions" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      }

      <mat-menu #menu="matMenu">
        <ng-content select="[mat-menu-item]" />
      </mat-menu>
    </div>

    <div class="list-context">
      <ng-content select="[mp-overview-list-context]" />
    </div>

    <div class="search-bar" [hidden]="!isSearchVisible">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ moduleTitle }} durchsuchen</mat-label>
        <input matInput #searchField (keyup)="emitSearchTerm(searchField.value)" />

        @if (searchField.value) {
          <button mat-icon-button matSuffix aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        }
      </mat-form-field>
    </div>

    <div class="list">
      <ng-content />
    </div>
  </div>

  <div
    class="detail-view-container"
    [class.layout-full]="obj.isSmallViewport"
    [class.navigated]="obj.isSmallViewport && route.children.length > 0"
  >
    <router-outlet />
  </div>
}
