import { permissions } from '@mpk/shared/domain';

enum GhxExportPermissionsEnum {
  None = 0,
  /**
   * Export Articles as GHX format file.
   */
  UseExport = 1,
}

export const GhxExportPermissions = permissions(GhxExportPermissionsEnum, {
  featureId: 'Export',
});
