import { Routes } from '@angular/router';

import { setupFeatureFlagsForModule } from '@core/shared/util';
import { provideCategoriesDataAccess } from '@mp/content-manager/categories/data-access';
import { provideOrganizationsDataAccess } from '@mp/kernel/organizations/data-access';
import { provideRolesDataAccess } from '@mp/kernel/roles/data-access';
import { provideUsersDataAccess } from '@mp/kernel/users/data-access';
import { provideAddressesDataAccess } from '@mp/organization-master-data/addresses/data-access';
import { provideFeedbackDataAccess } from '@mp/shared/helper-links/feedback/data-access';
import { provideHelpCenterDataAccess } from '@mp/shared/helper-links/help-center/data-access';
import { provideCostCentersDataAccess } from '@mpomd/shared/data-access';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

export const routes: Routes = [
  {
    path: 'no-organization',
    loadComponent: () => import('@mpe/shared').then((m) => m.NoOrganisationComponent),
  },
  {
    path: 'active-organization-selection',
    loadChildren: async () => (await import('@mpauth/routes')).ACTIVE_ORGANIZATION_SELECTOR_ROUTES,
  },
  {
    path: '',
    component: AppLayoutComponent,
    providers: [
      provideFeedbackDataAccess(),
      provideHelpCenterDataAccess(),
      provideOrganizationsDataAccess(),
      provideUsersDataAccess(),
      provideRolesDataAccess(),
      provideCategoriesDataAccess(),
      provideCostCentersDataAccess(),
      provideAddressesDataAccess(),
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LandingPageComponent,
        data: setupFeatureFlagsForModule({ customHeader: true }),
        title: 'Startseite',
      },
      {
        path: 'profile',
        loadChildren: async () => (await import('@mpauth/routes')).PROFILE_ROUTES,
      },

      /** Other routes are inserted here by route-builder during app init */
    ],
  },
];
