import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { DecimalPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';

import { InsightsBaseEvent, InsightsEvent, insightsNamedEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { SingleSelectFacet } from '@mp/shared/facets/domain';

import { ListBaseFacetComponent } from '../list-base-facet/list-base-facet.component';
import { ListFacetBucketDirective } from '../list-base-facet/list-facet-bucket.directive';

let nameCounter = 0;

/**
 * The context of the bucket template.
 */
export interface SingleSelectFacetBucketContext<TData extends object | unknown = unknown> {
  /**
   * The bucket object.
   */
  $implicit: SingleSelectFacet.Bucket<TData>;
  /**
   * The bucket object.
   */
  bucket: SingleSelectFacet.Bucket<TData>;
  /**
   * Specifies whether this is for the collapsed selected value display.
   */
  isCollapsed: boolean;
}

export interface SingleSelectFacetInsightsEventsConfig {
  filterExpand: InsightsEvent;
  filterCollapse: InsightsEvent;
  valueSelect: InsightsEvent;
}

@Component({
  selector: 'mp-single-select-facet',
  standalone: true,
  templateUrl: './single-select-facet.component.html',
  styleUrls: ['./single-select-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    DecimalPipe,
    NgTemplateOutlet,

    MatLegacyRadioModule,

    ListBaseFacetComponent,
    ListFacetBucketDirective,
  ],
})
export class SingleSelectFacetComponent<TData extends object | unknown = unknown> {
  private _facet!: SingleSelectFacet<TData>;
  private _searchable = false;
  private _maxVisibleItems?: number | null;
  private _showSelectionInfo = false;
  private _filterName = '';
  private _insightsEventsConfig: SingleSelectFacetInsightsEventsConfig | undefined = undefined;

  readonly radioButtonName = `mp-single-select-facet-${++nameCounter}`;

  constructor(@Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null) {}

  /**
   * The SingleSelectFacet object to display.
   */
  get facet(): SingleSelectFacet<TData> {
    return this._facet;
  }

  @Input()
  set facet(value: SingleSelectFacet<TData>) {
    this._facet = value;
    this.selectedBucket = value.buckets.find((b) => b.selected);
  }

  /**
   * Specifies the name of the filter.
   */
  @Input()
  set filterName(value: string) {
    this._filterName = value;
    this._insightsEventsConfig = this.getFilterInsightsEventsConfig(value);
  }

  get filterName() {
    return this._filterName;
  }

  /**
   * Specifies the icon of the filter component.
   */
  @Input()
  icon?: string;

  /**
   * If the current selection state should be stored, provide a unique key within the current instance
   * of `FacetSelectionService`. This is a constant value and must not be changed.
   */
  @Input()
  selectionKey?: string;

  /**
   * Specifies whether a badge with the number of selected values should be shown if a feature is collapsed.
   */
  get showSelectionInfo() {
    return this._showSelectionInfo;
  }

  @Input()
  set showSelectionInfo(value: BooleanInput) {
    this._showSelectionInfo = coerceBooleanProperty(value);
  }

  /**
   * Specifies whether a search field is shown to search the buckets.
   */
  get searchable() {
    return this._searchable;
  }

  @Input()
  set searchable(value: BooleanInput) {
    this._searchable = coerceBooleanProperty(value);
  }

  /**
   * The placeholder of the search field.
   */
  @Input()
  searchFieldPlaceholder = 'Merkmale durchsuchen';

  @Input() trackInsightsEvents = false;

  /**
   * Sets the number of items visible when not expanded.
   */
  @Input()
  get maxVisibleItems() {
    return this._maxVisibleItems;
  }

  set maxVisibleItems(value: NumberInput) {
    this._maxVisibleItems = coerceNumberProperty(value, null);
  }

  /**
   * The template to use for a bucket.
   */
  @ContentChild(TemplateRef)
  bucketTemplate?: TemplateRef<SingleSelectFacetBucketContext<TData>>;

  /**
   * Emits on selection change and provides the selected value of the facet.
   */
  @Output() readonly changed = new EventEmitter<string>();

  selectedBucket?: SingleSelectFacet.Bucket<TData>;

  onSelectionChanged(values: string[]): void {
    const selectedValue: string = values[0];
    this.selectedBucket = this._facet.buckets.find((b) => b.value === selectedValue);
    this.changed.emit(selectedValue);
  }

  onExpandedChange(expanded: boolean): void {
    if (!this.trackInsightsEvents || !this._insightsEventsConfig) {
      return;
    }

    const filterExpansionEvent: InsightsEvent = expanded
      ? this._insightsEventsConfig.filterExpand
      : this._insightsEventsConfig.filterCollapse;
    this.insightsEventsTrackingService?.trackEvent(filterExpansionEvent);
  }

  onValueSelect(valueLabel: string): void {
    if (this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig.valueSelect, {
        value: valueLabel,
      });
    }
  }

  private getFilterInsightsEventsConfig(filterName: string): SingleSelectFacetInsightsEventsConfig {
    return {
      filterExpand: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_EXPAND),
      filterCollapse: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_COLLAPSE),
      valueSelect: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_CHANGE),
    };
  }
}
