import { Injectable, Signal, computed } from '@angular/core';
import { patchState, signalStore, withState } from '@ngrx/signals';

import { SelectOption } from '@core/ui';
import { IdentityUser } from '@mp/kernel/users/domain';

interface UserSelectorState {
  searchTerm: string;
  availableUsers: IdentityUser[];
}

const INITIAL_STATE: UserSelectorState = {
  searchTerm: '',
  availableUsers: [],
};

const UserSelectorStoreBase = signalStore(withState(INITIAL_STATE));

@Injectable()
export class UserSelectorStore extends UserSelectorStoreBase {
  readonly filteredUsersOptions: Signal<SelectOption<IdentityUser>[]> = computed(() =>
    this.getFilteredUsersSelectOptions(),
  );

  setAvailableUsers(availableUsers: IdentityUser[]): void {
    patchState(this, { availableUsers });
  }

  setSearchTerm(searchTerm: string): void {
    patchState(this, { searchTerm });
  }

  private getFilteredUsersSelectOptions(): SelectOption<IdentityUser>[] {
    const searchTerm: string = this.searchTerm().toLowerCase();
    let filteredUsers: IdentityUser[];

    if (!searchTerm) {
      filteredUsers = this.availableUsers();
    } else {
      filteredUsers = this.availableUsers().filter(
        (user) => user.name.toLowerCase().includes(searchTerm) || user.email.toLowerCase().includes(searchTerm),
      );
    }

    return this.mapUsersToSelectOptions(filteredUsers);
  }

  private mapUsersToSelectOptions(users: IdentityUser[]): SelectOption<IdentityUser>[] {
    return users.map((user) => ({
      value: user,
      label: user.name,
      subLabel: user.email,
    }));
  }
}
