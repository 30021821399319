<mat-card class="table-card">
  <div class="table-card__table-container">
    <table
      class="condition-articles-table"
      mat-table
      [dataSource]="conditionArticles()"
      [fixedLayout]="true"
      [trackBy]="trackByFn"
    >
      <div *matNoDataRow class="no-data-row">Keine Artikel vorhanden</div>

      <ng-container matColumnDef="article" [sticky]="true">
        <th
          mat-header-cell
          class="condition-articles-table__header-cell condition-articles-table__header-cell-article"
          *matHeaderCellDef
          rowSpan="2"
          title="Artikel"
        >
          Artikel
        </th>
        <td
          mat-cell
          class="condition-articles-table__cell condition-articles-table__cell-article"
          *matCellDef="let row"
        >
          <mpctm-condition-article [conditionArticle]="row" />
        </td>
      </ng-container>

      <ng-container matColumnDef="unit" [sticky]="true">
        <th
          mat-header-cell
          class="condition-articles-table__header-cell condition-articles-table__header-cell-unit"
          *matHeaderCellDef
          rowSpan="2"
          title="Einheit"
        >
          Einheit
        </th>
        <td mat-cell class="condition-articles-table__cell condition-articles-table__cell-unit" *matCellDef="let row">
          <span class="article-unit">
            <span class="font-bold">{{ row.unit.unitShortName }}</span> {{ row.unit.unitLongName }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="clinicPrice" [sticky]="true">
        <th
          mat-header-cell
          class="condition-articles-table__header-cell condition-articles-table__header-cell-clinic-price"
          *matHeaderCellDef
          rowSpan="2"
          title="Klinik Preis"
        >
          Klinik Preis
        </th>
        <td
          mat-cell
          class="condition-articles-table__cell condition-articles-table__cell-clinic-price"
          *matCellDef="let row"
        >
          <mpctm-condition-article-price class="clinic-price" [articlePrice]="row.clinicPrice" />
        </td>
      </ng-container>

      <ng-container matColumnDef="ekgPrice" [sticky]="true">
        <th
          mat-header-cell
          class="condition-articles-table__header-cell condition-articles-table__header-cell-ekg-price"
          [class]="{ 'condition-articles-table__header-cell--selected-static-column': isEkgPriceSelected() }"
          *matHeaderCellDef
          rowSpan="2"
          title="EKG-Preis"
        >
          <div>EKG-Preis</div>
          <mat-radio-button
            [value]="ekgPriceColumnName"
            [checked]="isEkgPriceSelected()"
            [disabled]="disabled()"
            (change)="onPriceColumnSelection(ekgPriceColumnName, 'EKG-Preis')"
          />
        </th>
        <td
          mat-cell
          class="condition-articles-table__cell condition-articles-table__cell-ekg-price"
          [class]="{ 'condition-articles-table__cell--selected-static-column': isEkgPriceSelected() }"
          *matCellDef="let row"
        >
          <mpctm-condition-article-price
            class="ekg-price"
            [articlePrice]="row.ekgPrice.value"
            [percentageDifference]="row.ekgPrice.percentageDifference"
          />
        </td>
      </ng-container>

      @for (graduation of graduations(); let graduationGroupIndex = $index; track graduationGroupIndex) {
        <ng-container [matColumnDef]="getGraduationGroupColumnName(graduationGroupIndex)">
          <th
            mat-header-cell
            class="condition-articles-table__header-cell condition-articles-table__header-cell-graduations"
            *matHeaderCellDef
            [attr.colspan]="getGraduationGroupLevels(graduationGroupIndex).length"
            [title]="graduation.name + getGraduationType(graduation.labels)"
          >
            <div class="graduations-label">
              <span>
                <span class="font-bold">{{ graduation.name }}</span> {{ getGraduationType(graduation.labels) }}
              </span>
              <mat-icon class="graduations-label__icon material-icons-outlined">sync_problem</mat-icon>
            </div>
          </th>
        </ng-container>

        @for (
          graduationLevel of graduation.graduationLevels;
          let graduationLevelIndex = $index;
          track graduationLevelIndex
        ) {
          <ng-container
            *ngrxLet="{
              graduationLevelColumnName: getGraduationLevelColumnName(graduationGroupIndex, graduationLevelIndex),
              isGraduationLevelSelected:
                selectedPriceColumn() === getGraduationLevelColumnName(graduationGroupIndex, graduationLevelIndex)
            } as vm"
            [matColumnDef]="vm.graduationLevelColumnName"
          >
            <th
              mat-header-cell
              class="condition-articles-table__header-cell condition-articles-table__header-cell-graduation"
              [class]="{
                'condition-articles-table__header-cell--selected-column': vm.isGraduationLevelSelected
              }"
              *matHeaderCellDef
              [title]="graduationLevel.name"
            >
              <div>{{ graduationLevel.name }}</div>
              <mat-radio-button
                [value]="vm.graduationLevelColumnName"
                [checked]="vm.isGraduationLevelSelected"
                [disabled]="disabled()"
                (change)="
                  onPriceColumnSelection(
                    vm.graduationLevelColumnName,
                    graduation.name,
                    graduation.labels,
                    graduationLevel.name
                  )
                "
              />
            </th>
            <td
              mat-cell
              class="condition-articles-table__cell condition-articles-table__cell-graduation"
              [class]="{
                'condition-articles-table__cell--selected-column': vm.isGraduationLevelSelected
              }"
              *matCellDef="let row"
            >
              <mpctm-condition-article-price
                *ngrxLet="
                  row.graduations[graduationGroupIndex].graduationLevels[graduationLevelIndex]
                    .articlePrice as graduationLevelPrice
                "
                class="graduation-value"
                [articlePrice]="graduationLevelPrice.value"
                [percentageDifference]="graduationLevelPrice.percentageDifference"
              />
            </td>
          </ng-container>
        }
      }

      <tr
        mat-header-row
        class="condition-articles-table__header-row condition-articles-table__header-row--small"
        *matHeaderRowDef="headerMainColumns()"
      ></tr>
      <tr
        mat-header-row
        class="condition-articles-table__header-row condition-articles-table__header-row--secondary"
        *matHeaderRowDef="graduationLevelColumns()"
      ></tr>
      <tr mat-row class="condition-articles-table__row" *matRowDef="let row; columns: dataColumns()"></tr>
    </table>
  </div>
</mat-card>
