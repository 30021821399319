<mp-search-field
  class="raised"
  placeholder="Nach Export/Benutzer suchen"
  [searchTerm]="filtersForm.get('searchTerm')?.value ?? ''"
  (searched)="onSearchbarSubmitted($event)"
  (cleared)="onSearchbarClear()"
/>

<mpcm-warning-state-filter
  [active]="filtersForm.get('warningsOnly')?.value ?? false"
  (activeChange)="handleWarningStateFilterChange($event)"
/>

@if (filterSections$ | async; as filterSections) {
  <mp-filter-input-options [filterSections]="filterSections" (valueChanges)="handleFilterChange($event)" />
}
