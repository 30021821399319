import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OutputEmitterRef,
  effect,
  input,
  output,
  untracked,
} from '@angular/core';

import { ViewModelFromSignalStore } from '@core/shared/util';
import { ListItemSelectorComponent } from '@core/ui';
import { IdentityUser } from '@mp/kernel/users/domain';

import { UserSelectorStore } from './user-selector.store';

@Component({
  selector: 'mpk-user-selector',
  standalone: true,
  templateUrl: './user-selector.component.html',
  styleUrl: './user-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ListItemSelectorComponent],
  providers: [UserSelectorStore],
})
export class UserSelectorComponent {
  readonly availableUsers: InputSignal<IdentityUser[]> = input.required<IdentityUser[]>();

  readonly isLoadingUsers: InputSignal<boolean> = input.required<boolean>();

  protected readonly vm: ViewModelFromSignalStore<UserSelectorStore> = this.store;

  readonly selectedUsersChange: OutputEmitterRef<IdentityUser[]> = output<IdentityUser[]>();

  protected readonly getUserId = ({ userId }: IdentityUser): string => userId;

  constructor(private readonly store: UserSelectorStore) {
    effect(() => {
      const availableUsers: IdentityUser[] = this.availableUsers();
      untracked(() => this.store.setAvailableUsers(availableUsers));
    });
  }

  onUserSearchTermChange(searchTerm: string): void {
    this.store.setSearchTerm(searchTerm.trim());
  }

  onSelectedUsersChange(selectedUsers: IdentityUser[]): void {
    this.selectedUsersChange.emit(selectedUsers);
  }
}
