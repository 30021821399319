import { type EnvironmentProviders } from '@angular/core';

import {
  Amor3ExportPermissions,
  createAmor3ExportCategoriesPlugin,
  createAmor3ExportSettingsPlugin,
} from '@mp/integrations/amor3/export/feature';
import { provideModuleInfo } from '@mp/shared/util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { provideCategoriesExport } from '@mpcm/shared';
import { provideModuleOrganizationSettings, provideModulePermissions } from '@mpk/shared/data-access';

const moduleName = 'Amor3';

export function provideAmor3Feature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'AMOR 3',
    }),
    provideModulePermissions(moduleName, Amor3ExportPermissions),
    provideModuleOrganizationSettings(moduleName, createAmor3ExportSettingsPlugin),
    provideCategoriesExport(createAmor3ExportCategoriesPlugin),
  ];
}
