import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ConditionSupplierComponent, ConditionValidityPeriodComponent } from '@mp/contract-management/common/ui';
import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionParamComponent } from '../../../condition-param/condition-param.component';
import { ConditionSupplierAddressComponent } from '../../../condition-supplier-address/condition-supplier-address.component';

@Component({
  selector: 'mpctm-condition-details',
  standalone: true,
  templateUrl: './condition-details.component.html',
  styleUrl: './condition-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConditionParamComponent,
    ConditionSupplierComponent,
    ConditionSupplierAddressComponent,
    ConditionValidityPeriodComponent,
  ],
})
export class ConditionDetailsComponent {
  readonly conditionInfo: InputSignal<ConditionOverview> = input.required<ConditionOverview>();
}
