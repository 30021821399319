import { Injectable, Signal, computed, inject } from '@angular/core';
import { patchState, signalStore, withState } from '@ngrx/signals';

import { AddressesFacade } from '@mp/organization-master-data/addresses/data-access';
import { AddressListItem, AddressType } from '@mp/organization-master-data/addresses/domain';
import { ADDRESS_TYPE_FILTER_RECORD_KEY } from '@mp/organization-master-data/addresses/ui';

import { sortAddresses } from './sort-addresses';

interface AddressesListState {
  filter: AddressType[];
  filterVisible: boolean;
}

export const INITIAL_STATE: AddressesListState = {
  filter: [],
  filterVisible: false,
};

const AddressesListStoreBase = signalStore(withState(INITIAL_STATE));

@Injectable()
export class AddressesListStore extends AddressesListStoreBase {
  private readonly facade: AddressesFacade = inject(AddressesFacade);

  private readonly _addresses: Signal<AddressListItem[]> = this.facade.addresses;
  readonly addressesLoaded: Signal<boolean> = this.facade.addressesLoaded;

  readonly addresses: Signal<AddressListItem[]> = computed<AddressListItem[]>(() =>
    sortAddresses(this.getFilteredAddresses()),
  );

  fetchAddresses(searchTerm?: string): void {
    this.facade.fetchAddresses(searchTerm);
  }

  applyFilter(record: Record<string, AddressType[]>): void {
    const types: AddressType[] = record[ADDRESS_TYPE_FILTER_RECORD_KEY];

    patchState(this, { filter: types });
  }

  toggleFilter(): void {
    patchState(this, { filterVisible: !this.filterVisible() });
  }

  private getFilteredAddresses(): AddressListItem[] {
    const filter = this.filter();

    return filter.length
      ? this._addresses().filter((address) => address.addressTypes?.some((type) => filter.includes(type)))
      : this._addresses();
  }
}
