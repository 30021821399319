<mp-basic-flyout [title]="isEditMode ? 'Liste bearbeiten' : 'Neue Liste erstellen'" (closed)="cancel()">
  <div class="wrapper">
    <mat-form-field>
      <input
        #input
        matInput
        type="text"
        [maxlength]="maxLength"
        [formControl]="listenName"
        (keydown.enter)="saveList()"
      />
      <mat-label>Name der Liste</mat-label>
      <mat-hint [align]="'end'"> {{ listenName.value.length }} / {{ maxLength }} </mat-hint>
    </mat-form-field>

    <div class="flyout-interaction">
      <button mat-stroked-button class="interaction-button" color="accent" (click)="cancel()">Abbrechen</button>

      <button
        mat-stroked-button
        class="interaction-button"
        color="accent"
        [disabled]="listenName.invalid"
        (click)="saveList()"
      >
        {{ isEditMode ? 'Liste aktualisieren' : 'Liste erstellen' }}
      </button>

      @if (isEditMode) {
        <button mat-stroked-button class="interaction-button" color="accent" (click)="deleteList()">
          Liste löschen
        </button>
      }
    </div>
  </div>
</mp-basic-flyout>
