@if (showSearch()) {
  <mp-search-field
    class="search-filter"
    [placeholder]="searchFieldPlaceholder()"
    [searchTerm]="searchTerm()"
    [showClear]="false"
    (searched)="searchTerm.set($event)"
  />
}

<mp-filter-sort-select
  class="sort-filter"
  [sortOptions]="sortOptions()"
  [sortBy]="sortBy()"
  (sortByChange)="sortByChange.emit($event)"
/>
