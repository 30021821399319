import { Directive, ElementRef, inject, input } from '@angular/core';

type ValidationErrorTargetElProperty = 'innerText' | 'innerHTML' | 'title';

/**
 * Displays the first validation error of a form control, usually used with `mat-error`.
 *
 * The owning form field must have the `[mpAutoValidationErrors]` directive set.
 */
@Directive({
  selector: '[mpValidationError]',
  standalone: true,
})
export class ValidationErrorDirective {
  public readonly el = inject<ElementRef<HTMLElement>>(ElementRef);

  /**
   * Displays the first validation error of a form control, usually used with `mat-error`.
   * The owning form field must have the `[mpAutoValidationErrors]` directive set.
   *
   * Specifies the property of the element to bind the error message to.
   * Defaults to `innerText`.
   */
  public readonly targetProperty = input<ValidationErrorTargetElProperty, ValidationErrorTargetElProperty | ''>(
    'innerText',
    {
      alias: 'mpValidationError',
      transform: (input) => input || 'innerText',
    },
  );
}
