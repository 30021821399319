import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

import { FilterBadgeComponent } from '@core/ui';
import { InsightsBaseEvent, insightsNamedEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { AttributeFacet, FacetDrilldown, FacetFeature, FacetFeatureValue } from '@mp/shared/facets/domain';
import { FacetSelectionService } from '@mp/shared/facets/util';

import { AttributeFacetBucketDirective } from './attribute-facet-bucket.directive';
import { AttributeFacetBucketsGroupComponent } from './attribute-facet-buckets-group/attribute-facet-buckets-group.component';
import { AttributeFacetInsightsEventsConfig } from './attribute-facet-insights-config';

let defaultSelectionKeyCounter = 0;

@Component({
  selector: 'mp-attribute-facet',
  standalone: true,
  templateUrl: './attribute-facet.component.html',
  styleUrls: ['./attribute-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DecimalPipe,

    MatLegacyCheckboxModule,

    AttributeFacetBucketsGroupComponent,
    FilterBadgeComponent,
    AttributeFacetBucketDirective,
  ],
})
export class AttributeFacetComponent implements OnInit, OnDestroy {
  private _facet!: AttributeFacet;
  private _selectionState!: FacetSelectionService.SelectionState<FacetFeatureValue>;
  private _expansionState!: FacetSelectionService.SelectionState<FacetFeature>;
  private _searchExpansionState!: FacetSelectionService.SelectionState<FacetFeature>;
  private _showSelectionInfo = false;
  private _filterName = '';
  private _insightsEventsConfig: AttributeFacetInsightsEventsConfig | undefined = undefined;

  constructor(
    private readonly selectionService: FacetSelectionService,
    @Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null,
  ) {}

  @HostBinding('class')
  readonly class = 'mp-attribute-facet';

  /**
   * The AttributeFacet object to display.
   */
  get facet(): AttributeFacet {
    return this._facet;
  }

  @Input()
  set facet(value: AttributeFacet) {
    this._facet = value;

    if (this._selectionState) {
      this.setSelectedFromFacet(value);
    }
  }

  /**
   * If the current selection state should be stored, provide a unique key within the current instance
   * of `FacetSelectionService`. This is a constant value and must not be changed.
   */
  @Input()
  selectionKey?: string;

  /**
   * Specifies whether a badge with the number of selected values should be shown if a feature is collapsed.
   */
  get showSelectionInfo() {
    return this._showSelectionInfo;
  }

  @Input()
  set showSelectionInfo(value: BooleanInput) {
    this._showSelectionInfo = coerceBooleanProperty(value);
  }

  /**
   * Specifies the name of the filter.
   */
  @Input()
  set filterName(value: string) {
    this._filterName = value;
    this._insightsEventsConfig = this.getFilterInsightsEventsConfig(value);
  }

  get filterName() {
    return this._filterName;
  }

  /**
   * Specifies the icon of the filter component.
   */
  @Input()
  icon?: string;

  /**
   * The placeholder of the search field.
   */
  @Input()
  searchFieldPlaceholder = 'Durchsuchen';

  @Input({ transform: coerceBooleanProperty })
  searchable?: boolean;

  @Input() trackInsightsEvents = false;

  /**
   * Emits on selection change and provides the selected feature values for use in the search request.
   */
  @Output() readonly changed: EventEmitter<Array<FacetFeatureValue>> = new EventEmitter<Array<FacetFeatureValue>>();

  /**
   * Emits on expand/collapse/showMore and provides the corresponding feature along with its
   * optional expand limit in the form `<Feature>[:<ExpandLimit>]`. If expand limit is `0` the fearute
   * is collapsed and supposed to be removed from the search request.
   */
  @Output() readonly drilldown: EventEmitter<FacetDrilldown> = new EventEmitter<FacetDrilldown>();

  private readonly fallbackSelectionKey: string = `attributeFacet${++defaultSelectionKeyCounter}`;

  ngOnInit(): void {
    const selectionKey = this.selectionKey ?? this.fallbackSelectionKey;
    this._selectionState = this.selectionService.getSelectionState(selectionKey);
    this._expansionState = this.selectionService.getSelectionState(this.getExpansionStateSelectionKey(selectionKey));
    this._searchExpansionState = this.selectionService.getSelectionState(
      this.getSearchExpansionStateSelectionKey(selectionKey),
    );

    this.setSelectedFromFacet(this._facet);
  }

  ngOnDestroy(): void {
    if (!this.selectionKey) {
      this.selectionService.deleteSelectionState(this.fallbackSelectionKey);
      this.selectionService.deleteSelectionState(this.getExpansionStateSelectionKey(this.fallbackSelectionKey));
      this.selectionService.deleteSelectionState(this.getSearchExpansionStateSelectionKey(this.fallbackSelectionKey));
    }
  }

  isExpanded(value: FacetFeature): boolean {
    return this._expansionState.isSelected(value);
  }

  toggleExpanded(bucket: AttributeFacet.BucketLevel0): void {
    const value = bucket.value;
    const isSelected = this._expansionState.toggleSelected(value);

    if (this.trackInsightsEvents && this._insightsEventsConfig) {
      const insightsEvent: string = isSelected
        ? this._insightsEventsConfig.filterExpand
        : this._insightsEventsConfig.filterCollapse;
      this.insightsEventsTrackingService?.trackEvent(insightsEvent);
    }

    this.drilldown.emit(
      isSelected
        ? value
        : (`${value}:0` as FacetDrilldown) /*Storybook erkennt das nicht. Eigentlich kein Cast notwendig.*/,
    );
  }

  isSearchExpanded(value: FacetFeature): boolean {
    return this._searchExpansionState.isSelected(value);
  }

  storeSearchExpansionState(value: FacetFeature, isSearchExpanded: boolean): void {
    this._searchExpansionState.toggleSelected(value, isSearchExpanded);
  }

  isSelected(value: FacetFeatureValue): boolean {
    return this._selectionState.isSelected(value);
  }

  toggleSelected(bucket: AttributeFacet.BucketLevel1, groupLabel: string): void {
    const bucketSelected: boolean = this._selectionState.toggleSelected(bucket.value);

    if (bucketSelected && this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig.valueSelect, {
        group: groupLabel,
        value: bucket.label,
      });
    }

    this.changed.emit(Array.from(this.getSelectedValues()));
  }

  showMore(value: FacetFeature): void {
    this.drilldown.emit(`${value}:-1`);
  }

  private *getSelectedValues(): Iterable<FacetFeatureValue> {
    for (const v of this._selectionState.getSelectedValues()) {
      if (v[0] === '1') {
        yield v as FacetFeatureValue;
      }
    }
  }

  private setSelectedFromFacet(facet: AttributeFacet): void {
    const expanded: Record<FacetFeature, boolean> = {};
    const selected: Record<FacetFeatureValue, boolean> = {};

    facet.buckets.forEach((b) => {
      // Expand only when initial set
      if (!b.selected || !this._expansionState.isSet(b.value)) {
        expanded[b.value] = b.selected;
      } else {
        expanded[b.value] = this._expansionState.isSelected(b.value);
      }

      b.children.forEach((c) => {
        selected[c.value] = c.selected;
      });
    });

    this._expansionState.setSelected(expanded);
    this._selectionState.setSelected(selected);
  }

  clearSelection(): void {
    this.selectionService.getSelectionState(this.selectionKey ?? this.fallbackSelectionKey).setSelected({});
    this.changed.emit([]);

    if (this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig.filterReset);
    }
  }

  onGroupFilterSearchTermChange(groupFilterName: string): void {
    if (this.trackInsightsEvents && this._insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this._insightsEventsConfig.groupSearch, {
        group: groupFilterName,
      });
    }
  }

  private getExpansionStateSelectionKey(selectionKey: string): string {
    return `${selectionKey}_$$expansion`;
  }

  private getSearchExpansionStateSelectionKey(selectionKey: string): string {
    return `${selectionKey}_$$search-expansion`;
  }

  private getFilterInsightsEventsConfig(filterName: string): AttributeFacetInsightsEventsConfig {
    return {
      filterExpand: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_EXPAND),
      filterCollapse: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_COLLAPSE),
      valueSelect: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_CHANGE),
      filterReset: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_RESET),
      groupSearch: insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_SEARCH),
    };
  }
}
