<button mat-flat-button color="accent" (click)="request.emit(); $event.stopPropagation()">Anfragen</button>

<button
  mat-flat-button
  class="edit-condition-button"
  (click)="$event.stopPropagation()"
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="editConditionMenu"
>
  Kondition bearbeiten
  <mat-icon iconPositionEnd class="edit-condition-button__expand-icon material-icons-outlined">
    {{ menuTrigger.menuOpen ? 'expand_less' : 'expand_more' }}
  </mat-icon>
</button>

<mat-menu #editConditionMenu="matMenu">
  <button mat-menu-item (click)="$event.stopPropagation()">Neu zeichnen</button>
</mat-menu>
