import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { SignStatus } from '@mp/contract-management/common/domain';
import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionPendingConfirmationFooterComponent } from '../condition-pending-confirmation-footer/condition-pending-confirmation-footer.component';
import { ConditionSelectedGraduationFooterComponent } from '../condition-selected-graduation-footer/condition-selected-graduation-footer.component';
import { SignedConditionFooterComponent } from '../signed-condition-footer/signed-condition-footer.component';

@Component({
  selector: 'mpctm-overview-footer',
  standalone: true,
  templateUrl: './overview-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConditionPendingConfirmationFooterComponent,
    ConditionSelectedGraduationFooterComponent,
    SignedConditionFooterComponent,
  ],
})
export class OverviewFooterComponent {
  readonly condition: InputSignal<ConditionOverview> = input.required<ConditionOverview>();
  readonly selectedGraduationLabel: InputSignal<string | undefined> = input<string>();
  readonly isSelectedGraduationEKGPrice: InputSignal<boolean> = input<boolean>(false);

  readonly sign: OutputEmitterRef<ConditionOverview> = output<ConditionOverview>();
  readonly requestConfirmation: OutputEmitterRef<void> = output<void>();
  readonly reject: OutputEmitterRef<void> = output<void>();

  readonly SignStatus = SignStatus;
}
