import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mpctm-condition-additional-info-section',
  standalone: true,
  templateUrl: './condition-additional-info-section.component.html',
  styleUrl: './condition-additional-info-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionAdditionalInfoSectionComponent {
  readonly sectionTitle: InputSignal<string> = input.required<string>();
}
