import { type EnvironmentProviders } from '@angular/core';

import { MediioExportPermissions } from '@mp/integrations/mediio/export/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';

const moduleName = 'Mediio';

export function provideMediioFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Mediio',
    }),
    provideModulePermissions(moduleName, MediioExportPermissions),
  ];
}
