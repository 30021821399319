import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mpctm-condition-warning-message',
  standalone: true,
  templateUrl: './condition-warning-message.component.html',
  styleUrl: './condition-warning-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class ConditionWarningMessageComponent {}
