import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NonNullableFormBuilder } from '@angular/forms';
import { isEqual } from 'lodash';
import { Observable, tap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { FilterInputOptionsComponent, FilterInputOptionsData, FilterSection, SearchFieldComponent } from '@core/ui';
import { ArticleExportStateType, ExportJobProvider } from '@mp/content-manager/exports/data-access';
import { ExportHistoryInsightsEvent } from '@mp/content-manager/exports/util';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

import { ExportsFilter } from '../../models';
import { WarningStateFilterComponent } from '../warning-state-filter/warning-state-filter.component';

import { ExportsFilterStore } from './exports-filter.store';

@Component({
  selector: 'mpcm-exports-filter',
  standalone: true,
  templateUrl: './exports-filter.component.html',
  styleUrl: './exports-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, SearchFieldComponent, FilterInputOptionsComponent, WarningStateFilterComponent],
  providers: [ExportsFilterStore],
})
export class ExportsFilterComponent implements OnInit {
  @Input()
  set exportsFilter(value: ExportsFilter) {
    this.updateFormValue(value);
    this.store.setExportsFilters(value);
  }

  @Input()
  set exportsJobProviders(value: readonly ExportJobProvider[]) {
    this.store.setExportsJobProviders(value);
  }

  @Output() readonly exportsFilterChange: EventEmitter<ExportsFilter> = new EventEmitter<ExportsFilter>();

  readonly filtersForm = this.fb.group({
    searchTerm: '',
    stateType: '' as ArticleExportStateType | '',
    exportTypes: this.fb.control<string[]>([]),
    warningsOnly: this.fb.control<boolean>(false),
  });

  filterSections$: Observable<FilterSection<string, FilterInputOptionsData>[]> = this.store.filterSections$;

  readonly formValue$: Observable<Partial<ExportsFilter>> = this.filtersForm.valueChanges;

  constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly store: ExportsFilterStore,
    private readonly destroyRef: DestroyRef,
    @Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null,
  ) {}

  ngOnInit(): void {
    this.formValue$
      .pipe(
        distinctUntilChanged(isEqual),
        tap((formValue) => this.exportsFilterChange.emit(formValue)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  handleWarningStateFilterChange(isWarningStateFilterActive: boolean): void {
    const isWarningsOnlyEnabledEvent: ExportHistoryInsightsEvent = isWarningStateFilterActive
      ? ExportHistoryInsightsEvent.ENABLE_SHOW_WARNINGS_ONLY
      : ExportHistoryInsightsEvent.DISABLE_SHOW_WARNINGS_ONLY;
    this.insightsEventsTrackingService?.trackEvent(isWarningsOnlyEnabledEvent);

    this.filtersForm.patchValue({ warningsOnly: isWarningStateFilterActive });
  }

  handleFilterChange({ state, exportTypes }: { state?: [string]; exportTypes?: string[] }): void {
    if (state && this.getStateTypeFormControl().value !== state[0]) {
      this.filtersForm.patchValue({ stateType: (state[0] as ArticleExportStateType) || '' });
    }

    if (exportTypes && !isEqual(this.getExportTypesFormControl().value, exportTypes)) {
      this.getExportTypesFormControl().patchValue(exportTypes);
    }
  }

  onSearchbarSubmitted(searchTerm: string): void {
    this.insightsEventsTrackingService?.trackEvent(ExportHistoryInsightsEvent.PERFORM_SEARCH);
    this.filtersForm.patchValue({ searchTerm });
  }

  onSearchbarClear(): void {
    this.insightsEventsTrackingService?.trackEvent(ExportHistoryInsightsEvent.CLEAR_SEARCH_TERM);
    this.filtersForm.patchValue({ searchTerm: '' });
  }

  private updateFormValue({ searchTerm, stateType, exportTypes, warningsOnly }: ExportsFilter): void {
    this.filtersForm.setValue({ searchTerm, stateType, exportTypes, warningsOnly }, { emitEvent: false });
  }

  private getExportTypesFormControl(): FormControl<string[]> {
    return this.filtersForm.get('exportTypes') as FormControl<string[]>;
  }

  private getStateTypeFormControl(): FormControl<string> {
    return this.filtersForm.get('stateType') as FormControl<string>;
  }
}
