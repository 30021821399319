import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mpctm-condition-graduation-wrapper',
  standalone: true,
  templateUrl: './condition-graduation-wrapper.component.html',
  styleUrl: './condition-graduation-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionGraduationWrapperComponent {
  readonly label: InputSignal<string> = input.required<string>();
}
