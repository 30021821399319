import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionInfoSectionComponent } from '../../condition-info-section/condition-info-section.component';
import { InlineActionButtonComponent } from '../../inline-action-button/inline-action-button.component';

import { ConditionDetailsComponent } from './condition-details/condition-details.component';

@Component({
  selector: 'mpctm-condition-details-section',
  standalone: true,
  templateUrl: './condition-details-section.component.html',
  styleUrl: './condition-details-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, ConditionInfoSectionComponent, InlineActionButtonComponent, ConditionDetailsComponent],
})
export class ConditionDetailsSectionComponent {
  readonly conditionInfo: InputSignal<ConditionOverview> = input.required<ConditionOverview>();
}
