import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ConditionSigningDetails } from '@mp/contract-management/condition-signing-details/domain';

export const ConditionSigningDetailsActions = createActionGroup({
  source: 'Condition Management - Condition Signing Details',
  events: {
    fetchConditionSigningDetails: props<{ conditionId: string }>(),
    fetchConditionSigningDetailsSuccess: props<{ conditionSigningDetails: ConditionSigningDetails }>(),
    fetchConditionSigningDetailsError: emptyProps(),

    signCondition: emptyProps(),
    signConditionSuccess: props<{ conditionSigningDetails: ConditionSigningDetails }>(),
    signConditionError: emptyProps(),

    requestConfirmation: emptyProps(),
    requestConfirmationSuccess: props<{ conditionSigningDetails: ConditionSigningDetails }>(),
    requestConfirmationError: emptyProps(),
  },
});
