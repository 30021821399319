import { Injectable, Signal, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { ConditionSigningDetails } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionSigningDetailsState } from '../models';

import { ConditionSigningDetailsActions } from './condition-signing-details.actions';
import {
  selectConditionSigningDetails,
  selectConditionSigningDetailsState,
} from './condition-signing-details.selectors';

@Injectable()
export class ConditionSigningDetailsFacade {
  private readonly store$ = inject(Store);

  readonly conditionSigningDetailsState: Signal<ConditionSigningDetailsState> = this.store$.selectSignal(
    selectConditionSigningDetailsState,
  );

  readonly conditionSigningDetails: Signal<ConditionSigningDetails | undefined> =
    this.store$.selectSignal(selectConditionSigningDetails);

  readonly conditionId: Signal<string | undefined> = computed(
    () => this.conditionSigningDetails()?.conditionOverview.conditionId,
  );

  fetchConditionSigningDetails(conditionId: string): void {
    this.store$.dispatch(ConditionSigningDetailsActions.fetchConditionSigningDetails({ conditionId }));
  }

  signCondition(): void {
    this.store$.dispatch(ConditionSigningDetailsActions.signCondition());
  }

  requestConfirmation(): void {
    this.store$.dispatch(ConditionSigningDetailsActions.requestConfirmation());
  }
}
