import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { ConditionActionButtonComponent, PageFooterComponent } from '@mp/contract-management/common/ui';

import { ConditionRequestGenerationInfoComponent } from './condition-request-generation-info/condition-request-generation-info.component';

@Component({
  selector: 'mpctm-condition-pending-confirmation-footer',
  standalone: true,
  templateUrl: './condition-pending-confirmation-footer.component.html',
  styleUrl: './condition-pending-confirmation-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ConditionRequestGenerationInfoComponent, ConditionActionButtonComponent, PageFooterComponent],
})
export class ConditionPendingConfirmationFooterComponent {
  readonly requestDate: InputSignal<string> = input.required<string>();

  readonly creator: InputSignal<string> = input.required<string>();

  readonly accept: OutputEmitterRef<void> = output<void>();

  readonly reject: OutputEmitterRef<void> = output<void>();
}
