import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';

import { CategoryListItem } from '@mp/content-manager/categories/domain';

import { CategoriesActions } from './categories.actions';
import { selectCategories, selectCategoriesLoaded } from './categories.selectors';

@Injectable()
export class CategoriesFacade {
  readonly categories: Signal<CategoryListItem[]> = this.store$.selectSignal(selectCategories);
  readonly categoriesLoaded: Signal<boolean> = this.store$.selectSignal(selectCategoriesLoaded);

  constructor(private readonly store$: Store) {}

  fetchCategories(searchTerm?: string): void {
    this.store$.dispatch(CategoriesActions.fetchCategories({ searchTerm }));
  }
}
