<mp-dialog-header narrowPadding (closeDialog)="closeDialog()">
  <span class="dialog-header-label">
    Ändere Status von {{ data.conditionName }}
    <mpctm-entity-number-chip [entityNumber]="data.conditionNr" />
  </span>
</mp-dialog-header>

<div mat-dialog-content class="dialog-content">
  <span class="dialog-content__text-with-status">
    Du möchtest den Status dieser Kondition zu
    <mpctm-condition-sign-status
      class="dialog-content__sign-status"
      [conditionSignStatus]="SignStatus.SIGNED"
      [small]="true"
    />
    ändern.
  </span>

  <p class="dialog-content__text">
    Wenn du ein unterschriebenes Dokument hast, das den Statuswechsel bestätigt, kannst du es hier hochladen:
  </p>

  <mp-upload-form
    [status]="UploadFormStatus.PreUpload"
    [allowMultipleUploads]="false"
    [acceptedFileTypes]="'*'"
    [maxFileBytes]="0"
  >
    <mp-file-upload-label
      iconName="backup"
      buttonLabel="Hochladen"
      [status]="UploadFormStatus.PreUpload"
      [acceptedFileTypes]="'*'"
    />
  </mp-upload-form>

  @if (data.conditionAttachments; as attachments) {
    <mpctm-condition-attachments-list class="dialog-content__attachments" [attachments]="attachments" />
  }
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button color="accent" [mat-dialog-close]="false">Abbrechen</button>

  <mpctm-condition-action-button color="success" icon="draw" [label]="buttonLabel()" (click)="signCondition()" />
</div>
