@if (editMode) {
  <mpsm-supplier-autocomplete-input
    class="mpsm-supplier-autocomplete-input--highlight"
    [value]="lieferant"
    [inline]="true"
    appearance="standard"
    [placeholder]="placeholder"
    [icon]="icon"
    [disabled]="disabled"
    (valueChanges)="onLieferantChanges($event)"
  />
} @else if (!lieferant) {
  <mpsm-supplier-autocomplete-input
    class="mpsm-supplier-autocomplete-input--highlight"
    [inline]="true"
    appearance="standard"
    placeholder="Kein Schreibrecht"
    [disabled]="true"
  />
} @else {
  <span class="typ-icon">
    <mp-catalog-type-icon [type]="katalogtyp" />
    @if (lieferant) {
      <h2 class="katalog-lieferant">
        {{ lieferant?.name }}
      </h2>
    }
  </span>
}
