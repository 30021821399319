import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ConditionAttachment } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionAttachmentsListComponent } from '../../condition-attachments-list/condition-attachments-list.component';
import { ConditionInfoSectionComponent } from '../../condition-info-section/condition-info-section.component';
import { InlineActionButtonComponent } from '../../inline-action-button/inline-action-button.component';

@Component({
  selector: 'mpctm-condition-attachments-section',
  standalone: true,
  templateUrl: './condition-attachments-section.component.html',
  styleUrl: './condition-attachments-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ConditionInfoSectionComponent, ConditionAttachmentsListComponent, InlineActionButtonComponent],
})
export class ConditionAttachmentsSectionComponent {
  readonly attachments: InputSignal<ConditionAttachment[]> = input.required<ConditionAttachment[]>();
}
