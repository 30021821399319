import { DestroyRef, Directive, HostBinding, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

@Directive({
  selector: '[mpCharacterLimit][formControlName],[mpCharacterLimit][formControl]',
  exportAs: 'characterLimit',
  standalone: true,
})
export class CharacterLimitDirective implements OnInit {
  @HostBinding('attr.maxlength') maxLength?: string;
  @Input('mpCharacterLimit') limit?: string;

  constructor(
    private readonly formField: MatFormField,
    private readonly control: NgControl,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.maxLength = this.limit;
    this.control.valueChanges?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (value: string) => {
        this.updateHintLabel(value ? value.length : 0);
      },
    });

    this.updateHintLabel(this.control.value ? this.control.value.length : 0);
  }

  private updateHintLabel(length: number): void {
    this.formField.hintLabel = `${length} / ${this.limit}`;
  }
}
