import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  Signal,
  ViewChild,
  computed,
  input,
  model,
  output,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTable, MatTableModule } from '@angular/material/table';
import { LetDirective } from '@ngrx/component';

import { ConditionArticle, Graduation } from '@mp/contract-management/common/domain';
import { ConditionArticleComponent, ConditionArticlePriceComponent } from '@mp/contract-management/common/ui';
import { SignCondition } from '@mp/contract-management/condition-signing-details/domain';

@Component({
  selector: 'mpctm-condition-articles-table',
  standalone: true,
  templateUrl: './condition-articles-table.component.html',
  styleUrl: './condition-articles-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DecimalPipe,
    LetDirective,

    MatCardModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,

    ConditionArticleComponent,
    ConditionArticlePriceComponent,
  ],
})
export class ConditionArticlesTableComponent implements AfterViewInit {
  @ViewChild(MatTable, { static: true }) table: MatTable<ConditionArticle> | undefined;

  readonly conditionArticles: InputSignal<ConditionArticle[]> = input.required<ConditionArticle[]>();

  readonly disabled: InputSignal<boolean> = input<boolean>(false);

  readonly selectedPriceColumn: ModelSignal<string | undefined> = model<string | undefined>(undefined);

  readonly selectedGraduation: OutputEmitterRef<SignCondition> = output<SignCondition>();

  protected readonly ekgPriceColumnName: string = 'ekgPrice';

  private readonly baseColumns: string[] = ['article', 'unit', 'clinicPrice', this.ekgPriceColumnName];

  protected readonly graduations: Signal<Graduation[]> = computed(() => this.conditionArticles()[0]?.graduations ?? []);

  protected readonly graduationsGroupColumns: Signal<string[]> = computed(() => this.getGraduationsGroupsColumns());

  protected readonly headerMainColumns: Signal<string[]> = computed(() => [
    ...this.baseColumns,
    ...this.graduationsGroupColumns(),
  ]);

  protected readonly graduationLevelColumns: Signal<string[]> = computed(() => this.getAllGraduationLevelColumns());

  protected readonly dataColumns: Signal<string[]> = computed(() => [
    ...this.baseColumns,
    ...this.graduationLevelColumns(),
  ]);

  protected readonly isEkgPriceSelected: Signal<boolean> = computed(
    () => this.selectedPriceColumn() === this.ekgPriceColumnName,
  );

  ngAfterViewInit(): void {
    this.updateTableStickyColumnStyles();
  }

  @HostListener('window:resize', ['$event'])
  onWindowsResize() {
    this.updateTableStickyColumnStyles();
  }

  /**
   * NOTE: We need to update sticky column styles manually, because by default it doesn't react properly to data and screen size changes
   * See the issue: https://github.com/angular/components/issues/25396
   */
  private updateTableStickyColumnStyles(): void {
    this.table?.updateStickyColumnStyles();
  }

  onPriceColumnSelection(
    columnName: string,
    graduationName: string,
    labels?: Record<string, string>,
    graduationLevelName?: string,
  ): void {
    this.selectedPriceColumn.set(columnName);

    this.selectedGraduation.emit({
      value: columnName,
      label: this.getSelectedGraduationName(graduationName, labels, graduationLevelName),
      isEkgPrice: this.isEkgPriceSelected(),
    });
  }

  getGraduationGroupLevels(groupIndex: number): string[] {
    return this.graduations()[groupIndex].graduationLevels.map((_, index) =>
      this.getGraduationLevelColumnName(groupIndex, index),
    );
  }

  getGraduationGroupColumnName(groupIndex: number): string {
    return `graduationGroup_${groupIndex}`;
  }

  getGraduationLevelColumnName(groupIndex: number, levelIndex: number): string {
    return `graduationLevel_${groupIndex}_${levelIndex}`;
  }

  // TODO: PM - Verify this when the backend is ready
  getGraduationType(labels?: Record<string, string>): string {
    if (!labels) {
      return '';
    }

    const labelKeys = Object.keys(labels);
    const type = labelKeys.find((key) => key === 'Typ');
    return type ? ` (${labels[type]})` : '';
  }

  getSelectedGraduationName(
    graduationName: string,
    labels?: Record<string, string>,
    graduationLevelName?: string,
  ): string {
    const labelSuffix = graduationLevelName ? `${this.getGraduationType(labels)} > ${graduationLevelName}` : '';
    return `${graduationName} ${labelSuffix}`;
  }

  protected trackByFn(_index: number, { articleRecordId }: ConditionArticle): string {
    return articleRecordId;
  }

  private getGraduationsGroupsColumns(): string[] {
    return this.graduations().map((_, index) => this.getGraduationGroupColumnName(index));
  }

  private getAllGraduationLevelColumns(): string[] {
    return this.graduations()
      .map(({ graduationLevels }, groupIndex) =>
        graduationLevels.map((_, index) => this.getGraduationLevelColumnName(groupIndex, index)),
      )
      .flat();
  }
}
