<mpctm-condition-sign-status [conditionSignStatus]="conditionInfo().signStatus" />

<div class="condition-data truncate">
  <div class="condition-data__details truncate">
    <h2 class="condition-data__name truncate" [title]="conditionInfo().conditionName">
      {{ conditionInfo().conditionName }}
    </h2>

    @if (conditionInfo().conditionNumber; as conditionNumber) {
      <mpctm-entity-number-chip [entityNumber]="conditionNumber" />
    }
  </div>

  <mpctm-condition-relevant-articles-title
    class="condition-data__articles"
    [relevantArticlesCount]="conditionInfo().relevantArticlesCount"
    [totalArticlesCount]="conditionInfo().articlesCount"
  />
</div>

<div class="additional-info">
  <!-- TODO: PM - When it should be displayed? -->
  <mpctm-condition-base-price-status class="truncate" />

  <ng-content select="[condition-actions]" />
</div>
