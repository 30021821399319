import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { PageFooterComponent } from '@mp/contract-management/common/ui';

@Component({
  selector: 'mpctm-signed-condition-footer',
  standalone: true,
  templateUrl: './signed-condition-footer.component.html',
  styleUrl: './signed-condition-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, DatePipe, PageFooterComponent],
})
export class SignedConditionFooterComponent {
  readonly signedBy: InputSignal<string> = input.required<string>();

  readonly dateOfSigning: InputSignal<string | undefined> = input<string>();
}
