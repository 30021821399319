<mpctm-condition-sign-status-bar [conditionInfo]="conditionInfo()">
  <mpctm-condition-actions class="condition-actions" condition-actions (request)="request.emit()" />
</mpctm-condition-sign-status-bar>

<div class="condition-info">
  <mpctm-condition-details-section
    class="condition-details condition-info__section"
    [conditionInfo]="conditionInfo()"
  />

  <mpctm-condition-graduations-section
    class="conditions-list condition-info__section"
    [conditionGraduations]="conditionInfo().graduations"
  />

  <mpctm-condition-attachments-section
    class="condition-attachments condition-info__section"
    [attachments]="conditionInfo().attachments"
  />
</div>
