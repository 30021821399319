import { EnvironmentProviders } from '@angular/core';

import { provideModuleInfo } from '@mp/shared/util';

const moduleName = 'Export';

export function provideExportFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Export',
    }),
  ];
}
