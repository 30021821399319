@for (attachment of attachments(); track $index) {
  <div class="attachment">
    <!-- TODO Different icons based on file type? -->
    <mat-icon class="attachment__icon material-icons-outlined">description</mat-icon>

    <div class="attachment__info truncate">
      <div class="attachment__name truncate" *ngrxLet="attachment.name as attachmentName" [title]="attachmentName">
        {{ attachmentName }}
      </div>
      <div
        class="attachment__date truncate"
        *ngrxLet="attachment.date | date: 'dd.MM.yyyy' as attachmentDate"
        [title]="attachmentDate"
      >
        {{ attachmentDate }}
      </div>
    </div>

    <button
      class="attachment__menu-button"
      mat-icon-button
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="attachmentActionsMenu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
} @empty {
  <mpctm-no-data-message message="Keine Anhänge" />
}

<mat-menu #attachmentActionsMenu="matMenu">
  <button mat-menu-item (click)="$event.stopPropagation()">Löschen</button>
  <button mat-menu-item (click)="$event.stopPropagation()">Herunterladen</button>
</mat-menu>
