import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

import type { CategoryType } from '@core/shared/domain';

@Injectable({ providedIn: 'root' })
export class Amor3ExportCategoriesService {
  private readonly baseUrl = 'api/m/amor3/export/categories';
  private readonly httpClient = inject(HttpClient);

  exportCategories(categoryType: CategoryType, version: string): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${categoryType}/${version}`, {});
  }
}
