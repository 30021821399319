import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CategoryListItem } from '@mp/content-manager/categories/domain';

import { CategoriesActions } from './categories.actions';

export const categoriesFeatureKey = 'mp-categories';
type CategoriesEntityState = EntityState<CategoryListItem>;

const categoriesEntityAdapter = createEntityAdapter<CategoryListItem>({
  selectId: ({ version }: CategoryListItem) => version,
});

export interface CategoriesState {
  categories: CategoriesEntityState;
  categoriesLoaded: boolean;
}

export const initialState: CategoriesState = {
  categories: categoriesEntityAdapter.getInitialState(),
  categoriesLoaded: true,
};

export const categoriesReducer = createReducer(
  initialState,
  on(
    CategoriesActions.fetchCategories,
    (state): CategoriesState => ({
      ...state,
      categories: categoriesEntityAdapter.getInitialState(),
      categoriesLoaded: false,
    }),
  ),
  on(
    CategoriesActions.fetchCategoriesSuccess,
    (state, { categoriesPageResponse }): CategoriesState => ({
      ...state,
      categories: categoriesEntityAdapter.setAll(categoriesPageResponse.data, state.categories),
      categoriesLoaded: true,
    }),
  ),
  on(
    CategoriesActions.fetchCategoriesError,
    (state): CategoriesState => ({
      ...state,
      categoriesLoaded: true,
    }),
  ),
);

export const { selectAll: selectAllCategories } = categoriesEntityAdapter.getSelectors();
