import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  input,
  model,
  output,
} from '@angular/core';

import { FilterSortSelectComponent } from '../filter-sort-select/filter-sort-select.component';
import { SelectOption } from '../option';
import { SearchFieldComponent } from '../search-field/search-field.component';

@Component({
  selector: 'mp-context-filters',
  standalone: true,
  templateUrl: './context-filters.component.html',
  styleUrl: './context-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SearchFieldComponent, FilterSortSelectComponent],
})
export class ContextFiltersComponent {
  readonly searchTerm: ModelSignal<string> = model<string>('');
  readonly showSearch: InputSignal<boolean> = input<boolean>(true);
  readonly searchFieldPlaceholder: InputSignal<string> = input<string>('Durchsuchen');

  readonly sortBy: InputSignal<string | undefined> = input<string | undefined>();
  readonly sortOptions: InputSignal<SelectOption<string>[]> = input.required<SelectOption<string>[]>();

  readonly sortByChange: OutputEmitterRef<string> = output<string>();
}
