import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, filter, map, tap } from 'rxjs';

import { RouterFacade } from '@core/shared/data-access';
import { ConditionSigningDetailsFacade } from '@mp/contract-management/condition-signing-details/data-access';

import { CONDITION_ID_PARAM_NAME } from '../../route-params';

export interface CconditionSigningDetailsOverviewState {
  conditionId: string;
}

export const INITIAL_STATE: CconditionSigningDetailsOverviewState = {
  conditionId: '',
};

@Injectable()
export class ConditionSigningDetailsOverviewStore extends ComponentStore<CconditionSigningDetailsOverviewState> {
  constructor(
    private readonly conditionSigningDetailsFacade: ConditionSigningDetailsFacade,
    private readonly routerFacade: RouterFacade,
    private readonly activeRoute: ActivatedRoute,
  ) {
    super(INITIAL_STATE);

    this.initSelectConditionIdScopeEffect();
  }

  private readonly setSelectedConditionId = this.updater(
    (state, conditionId: string): CconditionSigningDetailsOverviewState => ({
      ...state,
      conditionId,
    }),
  );

  private initSelectConditionIdScopeEffect(): void {
    const selectedConditionIdSource$: Observable<string> = this.activeRoute.params.pipe(
      map((params) => params[CONDITION_ID_PARAM_NAME] ?? ''),
    );

    this.effect((conditionId$: Observable<string>) => {
      return conditionId$.pipe(
        tap((conditionId: string) => this.setSelectedConditionId(conditionId)),
        filter(Boolean),
        tap((conditionId) => this.fetchConditionSigningDetails(conditionId)),
      );
    })(selectedConditionIdSource$);
  }

  private fetchConditionSigningDetails(conditionId: string): void {
    this.conditionSigningDetailsFacade.fetchConditionSigningDetails(conditionId);
  }

  navigateBack(): void {
    this.routerFacade.back();
  }
}
