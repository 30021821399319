<mp-dialog-header narrowPadding (closeDialog)="closeDialog()">Anfrage für individuelle Kondition</mp-dialog-header>

<div mat-dialog-content class="dialog-content">
  <div class="dialog-info-box">
    <mat-icon class="dialog-info-box__icon material-icons-outlined">info</mat-icon>
    <span class="dialog-info-box__text">
      Für die Identifikation der Kondition werden die Konditionsinformationen mitversendet. Bitte ergänze deine Anfrage
      um eine Beschreibung.
    </span>
  </div>

  <div class="dialog-content-user">
    <p class="dialog-content-user__label">Gesendet wird als</p>
    <mpk-active-user [userName]="data.userName" [image]="data.image" [organizationName]="data.organizationName" />
  </div>

  <span class="dialog-content__text">Beschreibe deine Anfrage so genau wie möglich.</span>

  <mat-form-field class="dialog-content__input" appearance="fill">
    <textarea
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="3"
      placeholder="Eingabe deiner Anfrage"
      [(ngModel)]="requestText"
    ></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button color="accent" [mat-dialog-close]="true">Abbrechen</button>

  <mpctm-condition-action-button color="success" label="Anfrage versenden" (click)="sendRequest(requestText)" />
</div>
