<mat-card class="widget">
  <div class="widget-header">
    @if (icon) {
      <mat-icon class="widget-header__icon material-icons-outlined" [mpIcon]="icon" />
    }

    <h3 class="widget-header__title truncate">{{ widgetTitle }}</h3>

    @if (showSearchField) {
      <div class="widget-header__search-field-container" [title]="actionTitle">
        <mp-search-field
          [disabled]="!enabledByPermission"
          #searchField
          class="raised"
          placeholder="Durchsuchen"
          searchPosition="right"
          [searchDisabled]="searchField.searchTermValue().length < 2"
          (searched)="handleSearch($event)"
          (valueChange)="handleSearchTermChange($event)"
        />
      </div>
    }

    <ng-content select="widget-header-element" />

    @if (featurePageLink) {
      <a
        mat-icon-button
        class="widget-header__link-button"
        color="accent"
        [title]="actionTitle"
        [disabled]="!enabledByPermission"
        (click)="onLinkClick()"
      >
        <mat-icon>north_east</mat-icon>
      </a>
    }
  </div>

  <div class="widget-content">
    <ng-content />
  </div>

  <ng-content select="[footer]" />
</mat-card>
