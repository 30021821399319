import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { EntityItemComponent } from '../entity-item/entity-item.component';
import { EntityListHeadComponent } from '../entity-list-head/entity-list-head.component';
import { EntityType } from '../entity-type';

@Component({
  selector: 'mp-entity-list',
  standalone: true,
  templateUrl: './entity-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet],
})
export class EntityListComponent implements AfterViewInit {
  @ViewChild(TemplateRef) template!: TemplateRef<unknown>;

  @ContentChild(EntityListHeadComponent) head?: EntityListHeadComponent;
  @ContentChildren(EntityItemComponent) entityItems!: QueryList<EntityItemComponent>;

  @Input() icon?: string;
  @Input() editMode = false;

  // TODO: Maybe move this back into directive? Currently only serves purpose inside an <mp-entity-list-group>.
  @Input() label?: string;
  @Input() meta?: string;
  @Input() type?: EntityType;

  @Output() readonly add = new EventEmitter<void>();

  private readonly _count$ = new BehaviorSubject(0);
  readonly count$ = this._count$.asObservable();

  constructor(private readonly destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    this.entityItems.changes.pipe(startWith({}), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        const newCount = this.entityItems.filter(({ hidden }) => !hidden).length;
        this.updateCount(newCount);

        if (this.head) this.head.count = newCount;
      },
    });

    this.head?.searched
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((term) => this.filterItemsBySearchTerm(term));
  }

  updateCount(newCount?: number): void {
    if (newCount !== undefined) {
      this._count$.next(newCount);
    } else {
      const newCount = this.entityItems.filter(({ hidden }) => !hidden).length;
      this._count$.next(newCount);
    }
  }

  filterItemsBySearchTerm(searchTerm: string): void {
    if (searchTerm === '') {
      this.entityItems.forEach((item) => (item.hidden = false));
    } else {
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      this.entityItems.forEach((item) => {
        const lowercaseLabel = (item.label ?? '').toLowerCase();
        const matches = lowercaseLabel.includes(lowercaseSearchTerm);
        item.hidden = !matches;
      });
    }

    const newCount = this.entityItems.filter(({ hidden }) => !hidden).length;
    this.updateCount(newCount);

    if (this.head) this.head.count = newCount;
  }

  addClicked() {
    this.add.emit();
  }
}
