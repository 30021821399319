import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'mp-param',
  standalone: true,
  templateUrl: './param.component.html',
  styleUrl: './param.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormFieldModule, MatInputModule],
})
export class ParamComponent {
  @HostBinding('class') class = 'mp-param';

  readonly value: InputSignal<string | number | null> = input.required<string | number | null>();

  readonly suffix: InputSignal<string | undefined> = input<string>();
}
