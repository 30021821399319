<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Adressen" />
</ng-container>

<mp-overview moduleTitle="Adressen" [resultCount]="addresses().length" (search)="store.fetchAddresses($event)">
  <button mat-icon-button title-additional-controls color="accent" (click)="store.toggleFilter()">
    <mat-icon class="material-icons-outlined">filter_alt</mat-icon>
  </button>

  <button mat-menu-item routerLink="create">Neu</button>

  <mpomd-address-list-filter [hidden]="!filterVisible()" (filterChange)="store.applyFilter($event)" />

  @if (addressesLoaded()) {
    <cdk-virtual-scroll-viewport itemSize="100">
      <mp-overview-item *cdkVirtualFor="let address of addresses(); trackBy: trackByFn" [path]="address.addressId">
        <div class="title">
          <h2 class="title__street-name truncate">{{ address.street }}</h2>
          @if (address.addressTypes) {
            <mat-chip [removable]="false" class="chip-item">
              {{ address.addressTypes | mpomdAddressChipContent }}
            </mat-chip>
          }
        </div>
        <div>{{ address.zipCode }}, {{ address.city }}</div>
      </mp-overview-item>
    </cdk-virtual-scroll-viewport>
  } @else {
    <mp-spinner />
  }
</mp-overview>
