<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Preismanagement" [rootModuleLink]="['../']" />

  <mp-navigate-back-button (navigateBackClick)="onNavigateBack()" />
</ng-container>

@if (state().conditionSigningDetailsLoaded) {
  @if (details(); as details) {
    <div class="page-content">
      <div class="mp-page mp-page--centered">
        <div class="mp-page__main">
          <div class="mp-page__content mp-page--padding">
            <div class="overview">
              <mpctm-condition-signing-info-card
                [conditionInfo]="details.conditionOverview"
                (request)="openRequestDialog()"
              />

              <div class="overview__article-list">
                <mp-table-items-info
                  infoTitle="Artikelliste"
                  [totalItems]="details.conditionArticles.length"
                  [small]="true"
                />

                <mpctm-condition-articles-table
                  [conditionArticles]="details.conditionArticles"
                  [disabled]="
                    details.conditionOverview.signStatus === SignStatus.SIGNED ||
                    details.conditionOverview.signStatus === SignStatus.PENDING_CONFIRMATION
                  "
                  (selectedGraduation)="handleSelectedGraduation($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mpctm-overview-footer
      class="details-footer"
      [condition]="details.conditionOverview"
      [selectedGraduationLabel]="selectedGraduationLabel()"
      [isSelectedGraduationEKGPrice]="isSelectedGraduationEKGPrice()"
      (sign)="signCondition($event)"
      (requestConfirmation)="requestConfirmation()"
      (reject)="rejectCondition()"
    />
  }
} @else {
  <mp-spinner />
}
