import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mpctm-condition-base-price-status',
  standalone: true,
  templateUrl: './condition-base-price-status.component.html',
  styleUrl: './condition-base-price-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class ConditionBasePriceStatusComponent {
  readonly label = 'Basispreis besser als in der Klinik';
}
