<div class="drag">
  <div class="drag-handle">
    <!-- You need to project your own icon here,
         so that you can attach the [cdkDragHandle] directive: -->
    <ng-content />
  </div>

  @if (showIndexInput) {
    <div class="drag-index">
      <mat-form-field class="drag-index__form-field" appearance="fill" subscriptSizing="dynamic">
        <input
          class="drag-index__input"
          matInput
          type="number"
          [formControl]="indexInputControl"
          [min]="minIndex"
          [max]="maxIndex ?? null"
        />
      </mat-form-field>
    </div>
  }
</div>
