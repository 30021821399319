<mp-root-module-nav *mpHeaderElement="'left'" label="Plattformlieferanten" [rootModuleLink]="['../']" />

<mp-overview
  moduleTitle="Plattformlieferanten"
  [isCustomHeader]="true"
  [resultCount]="resultCount()"
  (search)="onSearch($event)"
>
  <mp-export-button
    title-additional-content
    class="export-button"
    disabledStateTooltipText="Du hast nicht das benötigte Recht."
    [isProcessing]="isProcessingExport()"
    (clicked)="onExportAll()"
  />

  <button mat-menu-item routerLink="create" disabled>Neu</button>

  <!-- height="864px" -->
  <mp-infinite-scroll
    [itemHeight]="86"
    [itemTemplate]="itemTemplate"
    [dataSource]="dataSource()"
    [scrollThresholdShare]="1"
  />

  <ng-template #itemTemplate let-supplier>
    <mp-overview-item [path]="supplier.supplierId">
      <div class="overview-item-wrapper">
        <mpsm-supplier-icon [supplier]="supplier" />
        <div>
          <h2 class="supplier-name truncate" [title]="supplier.name">{{ supplier.name }}</h2>
          <p class="supplier-external-number truncate" [title]="supplier.externalNumber">
            {{ supplier.externalNumber }}
          </p>
        </div>

        @if (supplier.isBlocked) {
          <mp-overview-item />
        }
      </div>
    </mp-overview-item>
  </ng-template>
</mp-overview>
