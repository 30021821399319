import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mpctm-no-data-message',
  standalone: true,
  templateUrl: './no-data-message.component.html',
  styleUrl: './no-data-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataMessageComponent {
  readonly message: InputSignal<string> = input.required<string>();
}
