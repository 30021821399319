import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OutputEmitterRef,
  WritableSignal,
  input,
  output,
  signal,
} from '@angular/core';

import { ExpandablePanelComponent } from '@core/ui';
import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionSigningInfoCardDetailsComponent } from './condition-signing-info-card-details/condition-signing-info-card-details.component';
import { ConditionSigningInfoCardHeaderComponent } from './condition-signing-info-card-header/condition-signing-info-card-header.component';

@Component({
  selector: 'mpctm-condition-signing-info-card',
  standalone: true,
  templateUrl: './condition-signing-info-card.component.html',
  styleUrl: './condition-signing-info-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ExpandablePanelComponent,
    ConditionSigningInfoCardHeaderComponent,
    ConditionSigningInfoCardDetailsComponent,
  ],
})
export class ConditionSigningInfoCardComponent {
  readonly conditionInfo: InputSignal<ConditionOverview> = input.required<ConditionOverview>();

  readonly panelExpanded: WritableSignal<boolean> = signal<boolean>(false);

  readonly request: OutputEmitterRef<void> = output<void>();
}
