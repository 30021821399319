import { permissions } from '@mpk/shared/domain';

// TODO: PM - Might be moved to dashboard page later

/**
 * Permissions for price management in contract management
 */
enum PriceManagementPermissionsEnum {
  None = 0,

  /**
   * Price management in contract management can be viewed and used
   */
  UsePriceManagement = 1,
}

export const PriceManagementPermissions = permissions(PriceManagementPermissionsEnum, {
  featureId: 'PriceManagement',
});
