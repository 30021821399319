<!-- TODO What if we do not have value for some of the sections? -->
<div class="section section--wrap-bordered">
  <mpctm-condition-param class="section__value" label="Lieferant">
    <mpctm-condition-supplier [supplier]="conditionInfo().supplier" />
  </mpctm-condition-param>

  <mpctm-condition-param class="section__value" label="Adresse">
    <mpctm-condition-supplier-address [supplierAddress]="conditionInfo().address" />
  </mpctm-condition-param>
</div>

<div class="section">
  <mpctm-condition-param class="section__value section__value--bordered" label="Gültigkeitszeitraum">
    <mpctm-condition-validity-period
      [validFrom]="conditionInfo().validFrom"
      [validUntil]="conditionInfo().validUntil"
    />
  </mpctm-condition-param>

  <mpctm-condition-param class="section__value section__value--bordered" label="Datenquelle">
    <span>{{ conditionInfo().dataSource }}</span>
  </mpctm-condition-param>
</div>
