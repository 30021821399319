import { Pipe, PipeTransform } from '@angular/core';

import { AddressType, addressTypeDescriptions } from '@mp/organization-master-data/addresses/domain';
import { ADDRESS_TYPES_PRIORITY_ORDER } from '@mp/organization-master-data/addresses/util';

@Pipe({
  name: 'mpomdAddressChipContent',
  standalone: true,
})
export class AddressChipContentPipe implements PipeTransform {
  transform(values: AddressType[]): string {
    const chipContent: string = this.getMainChipContent(values);
    const additionalItemsCount: number = values.length - 1;

    return additionalItemsCount >= 1 ? `${chipContent} +${additionalItemsCount}` : chipContent;
  }

  private getMainChipContent(values: AddressType[]): string {
    return ADDRESS_TYPES_PRIORITY_ORDER.reduce<string>(
      (acc, addressType) => acc || (values.includes(addressType) ? addressTypeDescriptions[addressType] : ''),
      '',
    );
  }
}
