import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { AvatarComponent } from '@core/ui';

@Component({
  selector: 'mpk-active-user',
  standalone: true,
  templateUrl: './active-user.component.html',
  styleUrl: './active-user.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent],
})
export class ActiveUserComponent {
  readonly userName: InputSignal<string> = input.required<string>();
  readonly organizationName: InputSignal<string> = input.required<string>();
  readonly image: InputSignal<string | null> = input<string | null>(null);
}
