import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse } from '@core/shared/domain';
import { QueryParams } from '@core/shared/util';
import { CategoryListItem, CategoryNode } from '@mp/content-manager/categories/domain';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
  private readonly baseUrl = 'api/m/contentmanagement/categories';

  constructor(private readonly http: HttpClient) {}

  fetchCategoryTypes(searchTerm?: string): Observable<PageResponse<CategoryListItem>> {
    const params = QueryParams.build().param('searchTerm', searchTerm).withoutPagination().toHttpParams();

    return this.http.get<PageResponse<CategoryListItem>>(`${this.baseUrl}/classifications`, { params });
  }

  fetchCategoryNodes(categoryType: string, categoryVersion: string, parentCode = ''): Observable<CategoryNode[]> {
    return this.http.get<CategoryNode[]>(`${this.baseUrl}/${categoryType}/${categoryVersion}/${parentCode}`);
  }

  exportCategoriesExcel(categoryType: string, categoryVersion: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/export/${categoryType}/${categoryVersion}`, {});
  }
}
