import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionActionsComponent } from '../condition-actions/condition-actions.component';
import { ConditionAttachmentsSectionComponent } from '../condition-attachments-section/condition-attachments-section.component';
import { ConditionDetailsSectionComponent } from '../condition-details-section/condition-details-section.component';
import { ConditionGraduationsSectionComponent } from '../condition-graduations-section/condition-graduations-section.component';
import { ConditionSignStatusBarComponent } from '../condition-sign-status-bar/condition-sign-status-bar.component';

@Component({
  selector: 'mpctm-condition-signing-info-card-header',
  standalone: true,
  templateUrl: './condition-signing-info-card-header.component.html',
  styleUrl: './condition-signing-info-card-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConditionAttachmentsSectionComponent,
    ConditionGraduationsSectionComponent,
    ConditionDetailsSectionComponent,
    ConditionActionsComponent,
    ConditionSignStatusBarComponent,
  ],
})
export class ConditionSigningInfoCardHeaderComponent {
  readonly conditionInfo: InputSignal<ConditionOverview> = input.required<ConditionOverview>();

  readonly request: OutputEmitterRef<void> = output<void>();
}
