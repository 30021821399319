import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageResponse } from '@core/shared/domain';
import { CategoryListItem } from '@mp/content-manager/categories/domain';

export const CategoriesActions = createActionGroup({
  source: 'Content-Management - Categories',
  events: {
    fetchCategories: props<{ searchTerm?: string }>(),
    fetchCategoriesSuccess: props<{ categoriesPageResponse: PageResponse<CategoryListItem> }>(),
    fetchCategoriesError: emptyProps(),
  },
});
