import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { Graduation, GraduationValueOutputPipe } from '@core/ui';

@Component({
  selector: 'mpctm-condition-graduation',
  standalone: true,
  templateUrl: './condition-graduation.component.html',
  styleUrl: './condition-graduation.component.scss',
  host: {
    class: 'mpctm-condition-graduation',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [KeyValuePipe, LetDirective, GraduationValueOutputPipe],
})
export class ConditionGraduationComponent {
  readonly conditionGraduation: InputSignal<Graduation> = input.required<Graduation>();
}
