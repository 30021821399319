import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { ChangeConditionStatusDialogComponent } from './change-condition-status-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'mpctm-change-condition-status-dialog-container',
};

@NgModule({
  imports: [ChangeConditionStatusDialogComponent],
  exports: [ChangeConditionStatusDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class ChangeConditionStatusDialogModule {}
