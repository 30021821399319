<table class="user-assignments-table" mat-table [dataSource]="users()" [trackBy]="trackByFn">
  <div *matNoDataRow class="no-data-row">Keine Benutzer zugewiesen</div>

  <ng-container matColumnDef="name">
    <mat-header-cell
      class="user-assignments-table__header-cell user-assignments-table__header-cell-name"
      *matHeaderCellDef
      title="Benutzer"
    >
      <span class="truncate">Benutzer</span>
    </mat-header-cell>

    <mat-cell class="user-assignments-table__cell user-assignments-table__cell-name" *matCellDef="let row">
      <span class="user-name truncate" [title]="row.name">{{ row.name }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell
      class="user-assignments-table__header-cell user-assignments-table__header-cell-email"
      *matHeaderCellDef
      title="E-Mail"
    >
      <span class="truncate">E-Mail</span>
    </mat-header-cell>

    <mat-cell class="user-assignments-table__cell user-assignments-table__cell-email" *matCellDef="let row">
      <span class="email truncate" [title]="row.email">{{ row.email }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell
      class="user-assignments-table__header-cell user-assignments-table__header-cell-actions"
      *matHeaderCellDef
    />

    <mat-cell class="user-assignments-table__cell user-assignments-table__cell-actions" *matCellDef="let row">
      <button mat-icon-button type="button" (click)="removeUserAssignment(row.userId, row.name)">
        <mat-icon class="action-button-icon material-icons-outlined">delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row class="user-assignments-table__header-row" *matHeaderRowDef="displayedCols" />
  <mat-row class="user-assignments-table__row" *matRowDef="let row; columns: displayedCols" />
</table>
