import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  InputSignalWithTransform,
  ModelSignal,
  OutputEmitterRef,
  ViewChild,
  WritableSignal,
  effect,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Observable, of, timer } from 'rxjs';
import { debounce, skip, switchMap, tap } from 'rxjs/operators';

import { TypedTemplateDirective } from '@core/shared/util';

import {
  AutocompleteOptionTemplateContext,
  AutocompleteOptionTemplateRef,
  AutocompletePanelComponent,
} from '../autocomplete';
import { SelectOption } from '../option';
import { SpinnerComponent } from '../spinner/spinner.component';

import { AutocompleteOptionsFetcher, defaultOptionsFetcher } from './options-fetcher';

@Component({
  selector: 'mp-entity-autocomplete',
  standalone: true,
  exportAs: 'mpEntityAutocomplete',
  templateUrl: './entity-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, SpinnerComponent, AutocompletePanelComponent, TypedTemplateDirective],
})
export class EntityAutocompleteComponent<T = unknown> {
  @HostBinding() readonly class = 'mp-entity-autocomplete';

  @ViewChild('autocompletePanel', { read: AutocompletePanelComponent, static: true })
  public autocompletePanel!: AutocompletePanelComponent<T>;

  readonly searchTerm: ModelSignal<string> = model<string>('');

  /**
   * Debounce time, between two search terms.
   *
   * **NOTE:** This property will only be used once during initialization!
   */
  readonly debounceTime: InputSignal<number> = input<number>(0);

  /**
   * Initial options for fetcher which doesn't fetch options itself, but just filters them.
   * For example this is how the `defaultOptionsFetcher` works.
   */
  readonly initialOptions: InputSignal<SelectOption<T>[] | undefined> = input<SelectOption<T>[]>();

  /**
   * Options fetcher function.
   * Default implementation filters initial options by search term.
   * For fetching options from the server, custom implementation can be provided.
   */
  readonly optionsFetcher: InputSignal<AutocompleteOptionsFetcher<T>> = input<AutocompleteOptionsFetcher<T>>(
    defaultOptionsFetcher<T>,
  );

  readonly isLoadingOptions: ModelSignal<boolean> = model<boolean>(false);

  readonly autoActiveFirstOption: InputSignalWithTransform<boolean, BooleanInput> = input<boolean, BooleanInput>(
    false,
    { transform: coerceBooleanProperty },
  );

  readonly optionTemplate: InputSignal<AutocompleteOptionTemplateRef<T> | undefined> =
    input<AutocompleteOptionTemplateRef<T>>();

  readonly panelClass: InputSignal<string> = input<string>('');

  /**
   * Whether to use virtual scroll if there are many options.
   */
  readonly useVirtualScroll: InputSignal<boolean> = input<boolean>(false);

  readonly optionSelected: OutputEmitterRef<SelectOption<T>> = output<SelectOption<T>>();

  readonly optionTemplateContextType!: AutocompleteOptionTemplateContext<T>;

  protected readonly options: WritableSignal<SelectOption<T>[]> = signal<SelectOption<T>[]>([]);

  protected readonly MIN_SEARCH_TERM_LENGTH = 3;

  constructor() {
    effect(() => {
      const initialOptions: SelectOption<T>[] | undefined = this.initialOptions();
      if (initialOptions != null) {
        untracked(() => this.options.set(initialOptions));
      }
    });

    this.initSearchTermListener();
  }

  filterBy(searchTerm: string | undefined): void {
    this.searchTerm.set(searchTerm ?? '');
  }

  private initSearchTermListener(): void {
    toObservable(this.searchTerm)
      .pipe(
        // Skip signal initial value
        skip(1),
        tap(() => this.isLoadingOptions.set(true)),
        debounce(() => timer(this.debounceTime())),
        switchMap((searchTerm) => this.fetchOptions(searchTerm)),
        tap(() => this.isLoadingOptions.set(false)),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (options) => this.options.set(options),
      });
  }

  private fetchOptions(searchTerm: string): Observable<SelectOption<T>[]> {
    if (searchTerm.length < this.MIN_SEARCH_TERM_LENGTH) {
      return of([]);
    }

    const optionsFetcher = this.optionsFetcher();
    const fetcherResult$ = optionsFetcher(searchTerm, this.initialOptions());
    return Array.isArray(fetcherResult$) ? of(fetcherResult$) : fetcherResult$;
  }
}
