import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { SupplierAddress } from '@mpsm/shared/domain';

@Component({
  selector: 'mpctm-condition-supplier-address',
  standalone: true,
  templateUrl: './condition-supplier-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionSupplierAddressComponent {
  readonly supplierAddress: InputSignal<SupplierAddress> = input.required<SupplierAddress>();

  protected readonly formattedAddressInfo: Signal<string> = computed(() => this.formatAddressInfo());

  private formatAddressInfo(): string {
    const supplierAddress: SupplierAddress = this.supplierAddress();

    const zipCode: string = supplierAddress.zipCode || '';
    const city: string = supplierAddress.city || '';
    const countryCode: string = supplierAddress.country?.laendercode || '';

    let formattedAddress = `${zipCode} ${city}`.trim();

    if (countryCode) {
      formattedAddress += `, ${countryCode}`;
    }

    return formattedAddress;
  }
}
