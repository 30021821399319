import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '@ngrx/component';

import { ConditionWarningMessageComponent } from '../../condition-warning-message/condition-warning-message.component';

@Component({
  selector: 'mpctm-condition-request-generation-info',
  standalone: true,
  templateUrl: './condition-request-generation-info.component.html',
  styleUrl: './condition-request-generation-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, DatePipe, MatIconModule, ConditionWarningMessageComponent],
})
export class ConditionRequestGenerationInfoComponent {
  readonly requestDate: InputSignal<string> = input.required<string>();

  readonly creator: InputSignal<string> = input.required<string>();
}
