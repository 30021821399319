import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CategoriesEffects, CategoriesFacade, categoriesFeatureKey, categoriesReducer } from '../store';

export function provideCategoriesDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(categoriesFeatureKey, categoriesReducer)),
    importProvidersFrom(EffectsModule.forFeature([CategoriesEffects])),
    makeEnvironmentProviders([CategoriesFacade]),
  ];
}
