import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InputSignal,
  ModelSignal,
  TemplateRef,
  computed,
  forwardRef,
  input,
  model,
  signal,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';

import { SelectOption } from '../option';
import { CompareWithFunction, SelectComponent, SelectOptionTemplateContext, SelectOptionTemplateRef } from '../select';

export type DropdownAppearance = 'standard' | 'fill';

@Component({
  selector: 'mp-dropdown',
  standalone: true,
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, FormsModule, MatIconModule, MatFormFieldModule, IconDirective, SelectComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent<T> implements ControlValueAccessor {
  readonly options: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly selectedValue: ModelSignal<T | undefined> = model<T | undefined>();

  readonly appearance: InputSignal<DropdownAppearance> = input<DropdownAppearance>('fill');
  readonly inline: InputSignal<boolean> = input<boolean>(false);

  readonly required: InputSignal<boolean> = input<boolean>(false);

  readonly dropdownIcon: InputSignal<string | undefined> = input<string | undefined>();

  readonly label: InputSignal<string | undefined> = input<string | undefined>();
  readonly labelTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();
  readonly placeholder: InputSignal<string | undefined> = input<string | undefined>();

  readonly errorMessage: InputSignal<string | undefined> = input<string | undefined>();

  readonly selectedOptionTemplate: InputSignal<SelectOptionTemplateRef<T> | undefined> =
    input<SelectOptionTemplateRef<T>>();

  readonly optionTemplate: InputSignal<SelectOptionTemplateRef<T> | undefined> = input<SelectOptionTemplateRef<T>>();

  readonly emptyOptionsListTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();

  readonly customCompareWithFunction: InputSignal<CompareWithFunction<T> | undefined> = input<CompareWithFunction<T>>();

  readonly optionTemplateContextType!: SelectOptionTemplateContext<T>;

  readonly disabled = input<boolean, BooleanInput>(false, {
    transform: coerceBooleanProperty,
  });
  protected readonly isDisabled = computed(() => this.disabled() || this.disabledByState());
  private readonly disabledByState = signal(false);

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onSelectionChange(): void {
    this.onChange(this.selectedValue());
  }

  writeValue(value: T | undefined): void {
    this.selectedValue.set(value);
    // NOTE: Mark component for check is necessary as value accessor will not trigger that by default with reactive forms
    this.cdr.markForCheck();
  }

  onChange = (_value: T | undefined) => {};

  onTouched = () => {};

  registerOnChange(onChange: (value: T | undefined) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledByState.set(isDisabled);
  }
}
