import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { ConditionActionButtonComponent, PageFooterComponent } from '@mp/contract-management/common/ui';

import { ConditionWarningMessageComponent } from '../condition-warning-message/condition-warning-message.component';

import { SelectedGraduationComponent } from './selected-graduation/selected-graduation.component';

@Component({
  selector: 'mpctm-condition-selected-graduation-footer',
  standalone: true,
  templateUrl: './condition-selected-graduation-footer.component.html',
  styleUrl: './condition-selected-graduation-footer.component.scss',
  host: {
    'class': 'mpctm-condition-selected-graduation-footer',
    '[class.mpctm-condition-selected-graduation-footer--require-info]': 'requireAdditionalInfo()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SelectedGraduationComponent,
    ConditionActionButtonComponent,
    ConditionWarningMessageComponent,
    PageFooterComponent,
  ],
})
export class ConditionSelectedGraduationFooterComponent {
  readonly selectedGraduationLabel: InputSignal<string> = input.required<string>();

  readonly validUntil: InputSignal<string | undefined> = input<string>();

  readonly requireAdditionalInfo: InputSignal<boolean> = input<boolean>(false);

  readonly signCondition: OutputEmitterRef<void> = output<void>();

  readonly requestConfirmation: OutputEmitterRef<void> = output<void>();

  protected readonly infoText = 'Diese Variante erfordert eine zusätzliche Bestätigung des Lieferanten';
}
