import { createReducer, on } from '@ngrx/store';

import { ConditionSigningDetailsState } from '../models';

import { ConditionSigningDetailsActions } from './condition-signing-details.actions';

export const conditionSigningDetailsFeatureKey = 'mpctm-core-condition-signing-details';

export const initialState: ConditionSigningDetailsState = {
  conditionSigningDetails: undefined,
  conditionSigningDetailsLoaded: true,
};

export const conditionSigningDetailsReducer = createReducer(
  initialState,
  on(
    ConditionSigningDetailsActions.fetchConditionSigningDetails,
    ConditionSigningDetailsActions.signCondition,
    ConditionSigningDetailsActions.requestConfirmation,
    (state): ConditionSigningDetailsState => ({
      ...state,
      conditionSigningDetails: undefined,
      conditionSigningDetailsLoaded: false,
    }),
  ),
  on(
    ConditionSigningDetailsActions.fetchConditionSigningDetailsSuccess,
    ConditionSigningDetailsActions.signConditionSuccess,
    ConditionSigningDetailsActions.requestConfirmationSuccess,
    (state, { conditionSigningDetails }): ConditionSigningDetailsState => ({
      ...state,
      conditionSigningDetails,
      conditionSigningDetailsLoaded: true,
    }),
  ),
  on(
    ConditionSigningDetailsActions.fetchConditionSigningDetailsError,
    ConditionSigningDetailsActions.signConditionError,
    ConditionSigningDetailsActions.requestConfirmationError,
    (state): ConditionSigningDetailsState => ({
      ...state,
      conditionSigningDetails: undefined,
      conditionSigningDetailsLoaded: false,
    }),
  ),
);
