import { Injectable, inject } from '@angular/core';

import { CATEGORIES_EXPORT_TOKEN } from '../injection-tokens';
import type { RegisteredCategoriesExportPlugin } from '../models';

@Injectable({ providedIn: 'root' })
export class CategoriesExportRegistrationService {
  private readonly registeredExportPlugins: RegisteredCategoriesExportPlugin[] = inject(CATEGORIES_EXPORT_TOKEN).sort(
    (a, b) => a.displayName.localeCompare(b.displayName),
  );

  /**
   * Gets the descriptions of the given available settings.
   * @returns The descriptions of the available settings.
   */
  public getRegisteredExportPlugins(): readonly RegisteredCategoriesExportPlugin[] {
    return [...this.registeredExportPlugins];
  }
}
