import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mpctm-condition-relevant-articles-title',
  standalone: true,
  templateUrl: './condition-relevant-articles-title.component.html',
  styleUrl: './condition-relevant-articles-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe],
})
export class ConditionRelevantArticlesTitleComponent {
  readonly relevantArticlesCount: InputSignal<number> = input.required<number>();
  readonly totalArticlesCount: InputSignal<number> = input.required<number>();
}
