import { SelectOption } from '@core/ui';

export const DEFAULT_TITLE_OPTIONS: SelectOption<string | null>[] = [
  { label: 'Keine Angabe', value: null, isResetOption: true },
  { label: 'Dr.', value: 'Dr.' },
  { label: 'Dr. med.', value: 'Dr. med.' },
  { label: 'Prof.', value: 'Prof.' },
  { label: 'Prof. Dr.', value: 'Prof. Dr.' },
  { label: 'Prof. Dr. med.', value: 'Prof. Dr. med.' },
  { label: 'PD Dr. med.', value: 'PD Dr. med.' },
  { label: 'Prof. Dr. Dr.', value: 'Prof. Dr. Dr.' },
];
