<mp-search-field
  class="search-bar"
  [(searchTerm)]="searchTerm"
  (searched)="onSearchTermChange($event)"
  (cleared)="clearSearchTerm()"
/>

<div class="items-list">
  <ng-content />
</div>
