import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { startWith } from 'rxjs/operators';

import { UtilPipesModule } from '@core/shared/util';

import { EntityIconPipe } from '../entity-icon.pipe';
import { EntityListHeadComponent } from '../entity-list-head/entity-list-head.component';
import { EntityListComponent } from '../entity-list/entity-list.component';
import { EntityType } from '../entity-type';

@Component({
  selector: 'mp-entity-list-group',
  standalone: true,
  templateUrl: './entity-list-group.component.html',
  styleUrl: './entity-list-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,

    MatExpansionModule,
    MatIconModule,
    MatButtonModule,

    EntityListHeadComponent,
    EntityIconPipe,
    UtilPipesModule,
  ],
})
export class EntityListGroupComponent implements AfterViewInit {
  @ContentChildren(EntityListComponent) listGroups!: QueryList<EntityListComponent>;

  @Input() type?: EntityType;
  @Input() icon?: string;

  @Input() editMode = false;

  showIcon = false;
  allExpanded = false;

  @Input() count = 0;

  @Output() readonly add = new EventEmitter<number | string>();

  constructor(private readonly destroyRef: DestroyRef) {}

  ngAfterViewInit(): void {
    this.listGroups.changes.pipe(startWith(this.listGroups), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        this.updateCount();
      },
    });
  }

  addClicked(id: number | string, event: Event): void {
    event.stopPropagation();
    this.add.emit(id);
  }

  toggleOpenCloseAllExpanders(accordion: { openAll: () => void; closeAll: () => void }): void {
    if (this.allExpanded) {
      accordion.closeAll();
      this.allExpanded = false;
    } else {
      accordion.openAll();
      this.allExpanded = true;
    }
  }

  updateCount(newCount?: number): void {
    if (newCount !== undefined) {
      this.count = newCount;
    } else {
      const newCount = this.listGroups
        .map((nestedList) => nestedList.entityItems.filter(({ hidden }) => !hidden).length)
        .reduce((a, b) => a + b, 0);

      this.count = newCount;
    }
  }

  handleSearchTerm(searchTerm: string): void {
    this.listGroups.forEach((list) => list.filterItemsBySearchTerm(searchTerm));
    this.updateCount();
  }
}
