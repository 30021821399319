import { ChangeDetectionStrategy, Component, OutputEmitterRef, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'mpctm-condition-actions',
  standalone: true,
  templateUrl: './condition-actions.component.html',
  styleUrl: './condition-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatMenuModule, MatIconModule],
})
export class ConditionActionsComponent {
  readonly request: OutputEmitterRef<void> = output<void>();
}
