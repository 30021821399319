<mp-basic-flyout *ngrxLet="displayedItems$ as displayedItems" [title]="title" (closed)="cancel()">
  <button mat-fab header-action-fab [disabled]="!selection.length" (click)="confirm()">
    <mat-icon>check</mat-icon>
  </button>

  <div class="selector-content">
    <div class="search-bar">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Durchsuchen</mat-label>
        <input matInput [formControl]="searchField" />
        <button *ngIf="searchField.value" mat-icon-button matSuffix aria-label="Clear" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="context-view-content">
      <cdk-virtual-scroll-viewport
        *ngIf="useVirtualScroll; else selectorItems"
        [itemSize]="virtualScrollItemSize"
        class="context-view-content__virtual-scroll"
      >
        <ng-container *cdkVirtualFor="let item of displayedItems">
          <ng-container *ngTemplateOutlet="selectorItem; context: { $implicit: item }" />
        </ng-container>
      </cdk-virtual-scroll-viewport>

      <ng-template #selectorItems>
        <ng-container *ngFor="let item of displayedItems">
          <ng-container *ngTemplateOutlet="selectorItem; context: { $implicit: item }" />
        </ng-container>
      </ng-template>

      <ng-template #selectorItem let-item>
        <mp-selector-item
          [selected]="isSelected(item)"
          [disabled]="getIsItemDisabled(item)"
          [matTooltip]="getItemTooltipText(item)"
          matTooltipClass="mp-flyout-selector__item-tooltip"
          (toggled)="toggleSelect(item)"
        >
          <ng-container *ngTemplateOutlet="template ?? selectionItemTemplate; context: { $implicit: item }" />
        </mp-selector-item>
      </ng-template>

      <ng-template #selectionItemTemplate let-item>
        <h4>{{ item.header }}</h4>
        <span *ngIf="item.subheader">{{ item.subheader }}</span>
      </ng-template>
    </div>
  </div>

  <mp-select-all-flyout-elements-button
    *ngIf="showSelectAllButton"
    content-action-fab
    [isAllItemsSelected]="(isAllItemsSelected$ | async) === true"
    [isNoItemsSelected]="isNoItemsSelected()"
    (selectAllClick)="selectItems(displayedItems)"
    (deselectAllClick)="deselectAllItems()"
  />
</mp-basic-flyout>
