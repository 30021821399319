import { permissions } from '@mp/kernel/permissions/domain';

enum CategoriesPermissionsEnum {
  None = 0,
  /**
   * View and export categories.
   */
  ViewAndExportCategories = 1,
}

export const CategoriesPermissions = permissions(CategoriesPermissionsEnum, {
  featureId: 'Categories',
});
