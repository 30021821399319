import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  input,
  model,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mp-input-form',
  standalone: true,
  templateUrl: './input-form.component.html',
  styleUrl: './input-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatButtonModule, MatTooltipModule, MatInputModule],
})
export class InputFormComponent {
  readonly label: InputSignal<string> = input<string>('');
  readonly disabled: InputSignal<boolean> = input<boolean>(false);
  readonly disabledStateTooltipText: InputSignal<string> = input<string>('');
  readonly inputValue: ModelSignal<string> = model<string>('');

  readonly updateValue: OutputEmitterRef<string> = output<string>();

  emitSave(): void {
    this.updateValue.emit(this.inputValue());
  }
}
