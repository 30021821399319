import { inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { type CategoryType } from '@core/shared/domain';
import { Amor3ExportCategoriesService } from '@mp/integrations/amor3/export/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type CategoriesExportPlugin } from '@mpcm/shared';
import { LoginContextService } from '@mpk/shared/data-access';

import { Amor3ExportPermissions } from '../permissions';

/**
 * Factory to create the AMOR3 categories export plugin.
 */
export function createAmor3ExportCategoriesPlugin(): CategoriesExportPlugin {
  const loginContextService = inject(LoginContextService);
  const exportService = inject(Amor3ExportCategoriesService);

  return {
    displayName: 'AMOR3 Warengruppen-Export',
    description: 'Exportiert die Warengruppen nach AMOR3',
    isEnabled: loginContextService.permission(Amor3ExportPermissions.UseExport),
    execute(categoryType: CategoryType, version: string): Promise<void> {
      return lastValueFrom(exportService.exportCategories(categoryType, version));
    },
  };
}
