<div class="entity-list-head__label truncate">
  <span class="entity-list-head__count">{{
    count | autoSingularOrPlural: genericEntityName[0] : genericEntityName[1]
  }}</span>
</div>

<div class="entity-list-head__actions">
  @if (showSearch) {
    <mp-search-field
      class="entity-list-head__search-field"
      [searchTerm]="searchTerm"
      (valueChange)="onSearchTermEmitted($event)"
    />
  }

  <div class="entity-list-head__additional-actions">
    <ng-content />
  </div>
</div>
