<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ label() }}</mat-label>
  <input matInput type="text" [(ngModel)]="inputValue" />
</mat-form-field>

<button
  mat-raised-button
  color="accent"
  class="save-button"
  [disabled]="disabled()"
  [matTooltip]="disabled() ? disabledStateTooltipText() : ''"
  (click)="emitSave()"
>
  Speichern
</button>
