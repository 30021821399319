import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  input,
  model,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { DateRange } from './date-range';

@Component({
  selector: 'mp-date-range-picker',
  standalone: true,
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule],
})
export class DateRangePickerComponent {
  readonly fromLabel: InputSignal<string> = input<string>('Von');
  readonly toLabel: InputSignal<string> = input<string>('Bis');

  readonly minDate: InputSignal<Date> = input<Date>(new Date());

  readonly startDate: ModelSignal<Date | null> = model<Date | null>(null);
  readonly endDate: ModelSignal<Date | null> = model<Date | null>(null);

  readonly dateRangeChange: OutputEmitterRef<DateRange> = output<DateRange>();

  onDateChange(): void {
    this.dateRangeChange.emit(this.getDateRange());
  }

  private getDateRange(): DateRange {
    return {
      startDate: this.startDate(),
      endDate: this.endDate(),
    };
  }
}
