import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ArticleConditionDiscountsComponent } from '@mpcm/shared';

import { ConditionAdditionalInfoSectionComponent } from '../condition-additional-info-section/condition-additional-info-section.component';

@Component({
  selector: 'mpctm-condition-signing-info-card-details',
  standalone: true,
  templateUrl: './condition-signing-info-card-details.component.html',
  styleUrl: './condition-signing-info-card-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ArticleConditionDiscountsComponent, ConditionAdditionalInfoSectionComponent],
})
export class ConditionSigningInfoCardDetailsComponent {
  readonly conditionInfo: InputSignal<ConditionOverview> = input.required<ConditionOverview>();
}
