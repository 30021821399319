<mp-dashboard-widget widgetTitle="Content Manager" icon="@enrich">
  <mpcm-file-upload-handler
    [disabled]="disabled"
    [acceptedFileTypes]="acceptedFileTypes"
    [maxFileBytes]="maxFileBytes"
  />
  @if (vm$ | async; as vm) {
    <div class="result__list">
      @if (!vm.isLoading) {
        <mat-list class="no-padding">
          @for (
            uploadedFile of vm.uploadedFiles
              | slice: 0 : (listExpanded ? vm.uploadedFiles.length : collapsedListLength);
            track $index
          ) {
            <mpcm-datasource-upload-list-item [uploadedFile]="uploadedFile">
              @if (hasManageUploadPermission()) {
                <mpcm-datasource-upload-list-item-actions-menu
                  [status]="uploadedFile.status"
                  (fileAttach)="onFileAttach(uploadedFile.uploadEntryId)"
                  (fileDelete)="onFileDelete(uploadedFile.uploadEntryId)"
                  (fileStatusChange)="onFileStatusChange(uploadedFile.uploadEntryId, $event)"
                />
              }
            </mpcm-datasource-upload-list-item>
          }
        </mat-list>
      } @else {
        <mp-spinner />
      }
      @if (listExpanded && vm.uploadedFilesPaginationMetadata; as pagination) {
        @if (pagination.totalRowCount > 10) {
          <mp-paginator [pagination]="pagination" (page)="onPageChange($event)" />
        }
      }

      @if (!vm.isLoading && vm.uploadedFiles.length > collapsedListLength) {
        <button class="footer" mat-button footer (click)="toggleListExpand()">
          <div class="footer__label">
            @if (!listExpanded) {
              Mehr anzeigen
              <mat-icon>arrow_drop_down</mat-icon>
            } @else {
              Weniger anzeigen
              <mat-icon>arrow_drop_up</mat-icon>
            }
          </div>
        </button>
      }
    </div>
  }
</mp-dashboard-widget>
