<mpctm-condition-warning-message>
  <span
    class="request-generation-info truncate"
    *ngrxLet="requestDate() | date: 'dd.MM.yyyy' as parsedRequestDate"
    [title]="'Anfrage generiert am ' + parsedRequestDate + ' von ' + creator()"
  >
    Anfrage generiert am <span class="font-bold">{{ parsedRequestDate }}</span> von
    <span class="font-bold">{{ creator() }}</span>
  </span>
</mpctm-condition-warning-message>
