import { OverlayModule } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OutputEmitterRef,
  Signal,
  ViewChild,
  WritableSignal,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import {
  atLeastOneDigit,
  atLeastOneLowercaseLetter,
  atLeastOneNonAlphanumericCharacter,
  atLeastOneUppercaseLetter,
  atLeastSixCharacters,
  passwordsMatchValidator,
} from './password-validators';

const passwordValidators = [
  atLeastSixCharacters,
  atLeastOneDigit,
  atLeastOneLowercaseLetter,
  atLeastOneUppercaseLetter,
  atLeastOneNonAlphanumericCharacter,
];

type Requirement = { isFulfilled: boolean; label: string };

export interface ChangePasswordInputValue {
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: 'mp-change-password-input',
  standalone: true,
  templateUrl: './change-password-input.component.html',
  styleUrl: './change-password-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,

    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,

    OverlayModule,
  ],
})
export class ChangePasswordInputComponent {
  @HostBinding() protected readonly class = 'mp-change-password-input';

  constructor(private readonly fb: NonNullableFormBuilder) {
    // Trigger confirmation validation on first change
    this.form.valueChanges
      .pipe(
        filter(({ newPasswordConfirmation }) => !!newPasswordConfirmation),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.form.get('newPasswordConfirmation')?.markAsTouched());
  }

  @ViewChild('passwordFormField', { static: true, read: ElementRef<HTMLElement> })
  protected passwordFormFieldRef!: ElementRef<HTMLElement>;

  readonly changePassword: OutputEmitterRef<ChangePasswordInputValue> = output<ChangePasswordInputValue>();

  protected readonly form = this.fb.group(
    {
      currentPassword: this.fb.control(''),
      newPassword: this.fb.control('', passwordValidators),
      newPasswordConfirmation: this.fb.control(''),
    },
    {
      validators: [passwordsMatchValidator('newPassword', 'newPasswordConfirmation')],
    },
  );

  protected readonly passwordRequirements: Signal<Requirement[]> = toSignal(this.buildPasswordRequirements$(), {
    requireSync: true,
  });

  protected readonly isRequirementsOverlayOpen: WritableSignal<boolean> = signal<boolean>(false);

  reset(): void {
    this.form.reset();
  }

  private buildPasswordRequirements$(): Observable<Requirement[]> {
    return this.form.valueChanges.pipe(
      startWith(() => this.buildPasswordRequirements()),
      map(() => this.buildPasswordRequirements()),
      map((requirements: Requirement[]) => (this.isEveryRequirementFulfilled(requirements) ? [] : requirements)),
    );
  }

  private buildPasswordRequirements(): Requirement[] {
    return [
      {
        isFulfilled: !this.form.getError('atLeastSixCharacters', 'newPassword'),
        label: 'Mindestens 6 Zeichen',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneLowercaseLetter', 'newPassword'),
        label: 'Mindestens ein Kleinbuchstabe',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneUppercaseLetter', 'newPassword'),
        label: 'Mindestens ein Großbuchstabe',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneDigit', 'newPassword'),
        label: 'Mindestens eine Zahl',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneNonAlphanumericCharacter', 'newPassword'),
        label: 'Mindestens ein Sonderzeichen',
      },
    ];
  }

  private isEveryRequirementFulfilled(requirements: Requirement[]): boolean {
    return requirements.every(({ isFulfilled }) => isFulfilled);
  }

  protected onButtonClick(): void {
    if (this.form.valid) {
      const { currentPassword, newPassword } = this.form.getRawValue();

      this.changePassword.emit({
        currentPassword,
        newPassword,
      });
    }
  }
}
