<mat-checkbox
  class="selector-element align-top"
  [checked]="isSelected()"
  (change)="isSelected() ? deselectValue(element().value) : selectValue(element().value)"
>
  <div class="selector-element__label truncate" [title]="element().label">{{ element().label }}</div>
  @if (element().subLabel; as subLabel) {
    <div class="selector-element__sub-label truncate" [title]="subLabel">{{ subLabel }}</div>
  }
</mat-checkbox>
