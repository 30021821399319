import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { SignStatus } from '@mp/contract-management/common/domain';
import { ConditionSignStatusComponent, EntityNumberChipComponent } from '@mp/contract-management/common/ui';
import { getSignStatusCssModifier } from '@mp/contract-management/common/util';
import { ConditionOverview } from '@mp/contract-management/condition-signing-details/domain';

import { ConditionBasePriceStatusComponent } from './condition-base-price-status/condition-base-price-status.component';
import { ConditionRelevantArticlesTitleComponent } from './condition-relevant-articles-title/condition-relevant-articles-title.component';

@Component({
  selector: 'mpctm-condition-sign-status-bar',
  standalone: true,
  templateUrl: './condition-sign-status-bar.component.html',
  styleUrl: './condition-sign-status-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'class()',
  },
  imports: [
    ConditionBasePriceStatusComponent,
    ConditionSignStatusComponent,
    ConditionRelevantArticlesTitleComponent,
    EntityNumberChipComponent,
  ],
})
export class ConditionSignStatusBarComponent {
  private readonly componentCssClass = 'mpctm-condition-sign-status-bar';

  readonly conditionInfo: InputSignal<ConditionOverview> = input.required<ConditionOverview>();

  protected readonly class: Signal<string> = computed(() => {
    return `${this.componentCssClass} ${this.getComponentStateCssClass()}`;
  });

  protected getComponentStateCssClass(): string {
    const signStatus: SignStatus = this.conditionInfo().signStatus;
    const stateCssModifier: string = getSignStatusCssModifier(signStatus);

    return this.componentCssClass + stateCssModifier;
  }
}
