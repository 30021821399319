import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { CATEGORIES_EXPORT_TOKEN } from '../injection-tokens';
import type { RegisteredCategoriesExportPlugin } from '../models';

/**
 * Registeres a module's categories export plugins.
 */
export function provideCategoriesExport(
  ...exportPluginFactories: (() => RegisteredCategoriesExportPlugin)[]
): EnvironmentProviders {
  return makeEnvironmentProviders(
    exportPluginFactories.map((factory) => ({
      provide: CATEGORIES_EXPORT_TOKEN,
      useFactory: factory,
      multi: true,
    })),
  );
}
