import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  ViewChild,
  input,
  model,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyInput, MatLegacyInputModule } from '@angular/material/legacy-input';

@Component({
  selector: 'mp-filter-search-field',
  standalone: true,
  templateUrl: './filter-search-field.component.html',
  styleUrl: './filter-search-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatAutocompleteModule, MatLegacyFormFieldModule, MatIconModule, MatLegacyInputModule],
})
export class FilterSearchFieldComponent {
  @HostBinding() readonly class = 'mp-filter-search-field';

  @ViewChild('inputElement', { static: true }) inputElement!: MatLegacyInput;

  readonly searchFieldPlaceholder: InputSignal<string> = input.required<string>();

  readonly value: ModelSignal<string> = model<string>('');

  readonly autocompleteControl: InputSignal<MatAutocomplete | undefined> = input<MatAutocomplete | undefined>();

  readonly search: OutputEmitterRef<string> = output<string>();
}
