@if (showFieldSelector) {
  <mp-query-field-selector
    class="query-field-selector"
    [selectedField]="field"
    [fieldOptions]="fieldOptions"
    (selectedFieldChange)="onSearchQueryFieldSelected($event)"
  />
}

<mp-search-field
  #searchField
  class="raised search-field"
  [class]="{ stacked: showFieldSelector }"
  [placeholder]="searchFieldPlaceholder"
  [searchTerm]="searchTerm"
  (searchTermChange)="onSearch($event)"
  (searched)="onManualSearch($event)"
  (cleared)="onManualSearch('')"
/>
