<div class="details-column">
  <mpctm-condition-additional-info-section sectionTitle="Skonto">
    <!-- TODO: PM - Adjust when model is known -->
    <mpcm-article-condition-discounts [condition]="$any(conditionInfo())" />
  </mpctm-condition-additional-info-section>
</div>

<div class="details-column">
  <mpctm-condition-additional-info-section sectionTitle="Lieferbedingungen">
    @for (termOfDelivery of conditionInfo().contract?.termsOfDelivery; track $index) {
      <div [innerHTML]="termOfDelivery"></div>
    }
  </mpctm-condition-additional-info-section>

  <mpctm-condition-additional-info-section sectionTitle="Versandzuschläge">
    <div [innerHTML]="conditionInfo().contract?.shippingSurcharges"></div>
  </mpctm-condition-additional-info-section>
</div>
