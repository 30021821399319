import { permissions } from '@mpk/shared/domain';

enum MediioExportPermissionsEnum {
  None = 0,
  /**
   * Export articles as Mediio condition format file.
   */
  UseExportConditionFormat = 1,
  /**
   * Export articles as Mediio Platform format file.
   */
  UseExportPlatformFormat = 2,
}

export const MediioExportPermissions = permissions(MediioExportPermissionsEnum, {
  featureId: 'Export',
});
