import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  Signal,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { HeaderElementDirective } from '@core/shared/util';
import {
  NavigateBackButtonComponent,
  RootModuleNavComponent,
  SpinnerComponent,
  TableItemsInfoComponent,
} from '@core/ui';
import { SignStatus } from '@mp/contract-management/common/domain';
import {
  ConditionSigningDetailsFacade,
  ConditionSigningDetailsState,
} from '@mp/contract-management/condition-signing-details/data-access';
import {
  ConditionOverview,
  ConditionSigningDetails,
  SignCondition,
} from '@mp/contract-management/condition-signing-details/domain';
import {
  ChangeConditionStatusDialogComponent,
  ChangeConditionStatusDialogData,
  ChangeConditionStatusDialogModule,
  ConditionArticlesTableComponent,
  ConditionSigningInfoCardComponent,
  OverviewFooterComponent,
  RequestDialogComponent,
  RequestDialogData,
  RequestDialogModule,
} from '@mp/contract-management/condition-signing-details/ui';

import { ConditionSigningDetailsOverviewStore } from './condition-signing-details-overview.store';

@Component({
  selector: 'mpctm-condition-signing-details-overview',
  standalone: true,
  templateUrl: './condition-signing-details-overview.component.html',
  styleUrl: './condition-signing-details-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,

    ConditionArticlesTableComponent,
    ConditionSigningInfoCardComponent,
    OverviewFooterComponent,

    ChangeConditionStatusDialogModule,
    HeaderElementDirective,
    NavigateBackButtonComponent,
    RequestDialogModule,
    RootModuleNavComponent,
    SpinnerComponent,
    TableItemsInfoComponent,
  ],
  providers: [ConditionSigningDetailsOverviewStore],
})
export class ConditionSigningDetailsOverviewComponent implements OnInit {
  private readonly facade = inject(ConditionSigningDetailsFacade);
  private readonly dialog = inject(MatDialog);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(ConditionSigningDetailsOverviewStore);

  readonly SignStatus = SignStatus;

  readonly conditionSigningDetailsState: Signal<ConditionSigningDetailsState> =
    this.facade.conditionSigningDetailsState;

  protected readonly state: Signal<ConditionSigningDetailsState> = computed(() => this.conditionSigningDetailsState());

  protected readonly details: Signal<ConditionSigningDetails | undefined> = computed(
    () => this.state().conditionSigningDetails,
  );

  readonly selectedGraduationLabel: WritableSignal<string | undefined> = signal<string | undefined>(undefined);

  readonly isSelectedGraduationEKGPrice: WritableSignal<boolean> = signal<boolean>(false);

  ngOnInit(): void {
    this.facade.fetchConditionSigningDetails('');
  }

  handleSelectedGraduation(selectedGraduation: SignCondition): void {
    this.selectedGraduationLabel.set(selectedGraduation.label);
    this.isSelectedGraduationEKGPrice.set(selectedGraduation.isEkgPrice);
  }

  openRequestDialog(): void {
    this.dialog
      .open<RequestDialogComponent, RequestDialogData>(RequestDialogComponent, {
        data: {
          userName: 'Super Admin',
          image: null,
          organizationName: 'Admin-Organisation',
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  signCondition(condition: ConditionOverview): void {
    this.dialog
      .open<ChangeConditionStatusDialogComponent, ChangeConditionStatusDialogData>(
        ChangeConditionStatusDialogComponent,
        {
          data: {
            conditionName: condition.conditionName,
            conditionNr: condition.conditionNumber ?? '',
            conditionAttachments: [],
          },
        },
      )
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((confirmed) => {
        if (confirmed) {
          // TODO: PM - Add attachments and conditionId later
          this.facade.signCondition();
        }
      });
  }

  requestConfirmation(): void {
    // TODO: PM - Add request confirmation preview dialog later
    this.facade.requestConfirmation();
  }

  // TODO: PM - This simulates a reset for now. Should be adjusted later
  rejectCondition(): void {
    this.facade.fetchConditionSigningDetails('');
  }

  onNavigateBack(): void {
    this.store.navigateBack();
  }
}
