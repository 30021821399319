import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DecimalPipe, NgTemplateOutlet, SlicePipe } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Signal,
  ViewChild,
  WritableSignal,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
// eslint-disable-next-line no-restricted-imports
import { MatLegacyInput } from '@angular/material/legacy-input';
import { BehaviorSubject, Observable, combineLatest, tap, throttleTime } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

import { IconDirective, TypedTemplateDirective } from '@core/shared/util';
import { AutocompletePanelComponent, FilterSearchFieldComponent, SelectComponent, SelectOption } from '@core/ui';
import { InsightsEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService, SEARCH_EVENT_MIN_INTERVAL_TIME } from '@mp/shared/app-insights/util';
import { FacetBase, ListFacetBase, SingleSelectFacet, TreeFacet } from '@mp/shared/facets/domain';
import { FacetSelectionService, flattenTreeFacetBucket } from '@mp/shared/facets/util';

let defaultSelectionKeyCounter = 0;
const expandedSelection = 'expanded';
const searchExpandedSelection = 'searchExpanded';
const searchFocusedSelection = 'searchFocused';

function getSelectedLeaf<TData>(n: TreeFacet.Bucket<TData>): TreeFacet.Bucket<TData> | undefined {
  if (n.selected) {
    if (n.children?.length) {
      for (const c of n.children) {
        const s = getSelectedLeaf(c);
        if (s != null) {
          return s;
        }
      }
    }
    return n;
  }
  return undefined;
}

function* filterBuckets<TData>(
  buckets: Iterable<TreeFacet.Bucket<TData>>,
  searchTerm: string,
): Iterable<TreeFacet.Bucket<TData>> {
  searchTerm = searchTerm.trim().toLowerCase();

  for (const bucket of buckets) {
    if (
      !searchTerm ||
      bucket.label.trim().toLowerCase().includes(searchTerm) ||
      bucket.value.trim().toLowerCase().includes(searchTerm)
    ) {
      yield bucket;
    }
  }
}

export interface TreeFacetInsightsEventsConfig {
  filterExpand: InsightsEvent;
  filterCollapse: InsightsEvent;
  valueTypeSelect: InsightsEvent;
  valueSelect: InsightsEvent;
  filterReset: InsightsEvent;
  filterSearch: InsightsEvent;
}

@Component({
  selector: 'mp-tree-facet',
  standalone: true,
  templateUrl: './tree-facet.component.html',
  styleUrl: './tree-facet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SlicePipe,
    NgTemplateOutlet,
    DecimalPipe,
    ReactiveFormsModule,

    MatIconModule,
    MatLegacyButtonModule,

    IconDirective,
    FilterSearchFieldComponent,
    SelectComponent,
    AutocompletePanelComponent,
    TypedTemplateDirective,
  ],
})
export class TreeFacetComponent<TData extends object | unknown = unknown>
  implements OnInit, OnDestroy, AfterViewChecked
{
  @HostBinding('attr.title')
  private readonly _title = '';

  @HostBinding('class')
  readonly class = 'mp-tree-facet';

  /**
   * The TreeFacet object to display.
   */
  get facet(): TreeFacet<TData> {
    return this._facet;
  }

  @Input()
  set facet(value: TreeFacet<TData>) {
    this._facet = value;

    this._allBuckets$.next(Array.from(flattenTreeFacetBucket(value.root)).slice(1));

    if (this._selectionState) {
      this.setSelectedFromFacet(value);
    }

    this.selectedBucket = getSelectedLeaf(value.root);
  }

  /**
   * An optional facet with selectable types.
   */
  get typeFacet(): SingleSelectFacet | undefined {
    return this._typeFacet;
  }

  @Input()
  set typeFacet(value: SingleSelectFacet | null | undefined) {
    this._typeFacet = value ?? undefined;
    this.setSelectedTypeFromFacet(this._typeFacet);
    this.setTypeFacetSelectOptions(this._typeFacet);
  }

  /**
   * Specifies the title of the filter component.
   */
  @Input()
  title?: string;

  /**
   * Specifies the icon of the filter component.
   */
  @Input()
  icon?: string;

  /**
   * If the current selection state should be stored, provide a unique key within the current instance
   * of `FacetSelectionService`. This is a constant value and must not be changed.
   */
  @Input() selectionKey?: string;

  /**
   * Specifies whether a badge with the number of selected values should be shown if a feature is collapsed.
   */
  get showSelectionInfo() {
    return this._showSelectionInfo;
  }

  @Input()
  set showSelectionInfo(value: BooleanInput) {
    this._showSelectionInfo = coerceBooleanProperty(value);
  }

  /**
   * Specifies whether a search field is shown to search the buckets.
   */
  get searchable() {
    return this._searchable;
  }

  @Input()
  set searchable(value: BooleanInput) {
    this._searchable = coerceBooleanProperty(value);
  }

  /**
   * The placeholder of the search field.
   */
  @Input()
  searchFieldPlaceholder = 'Merkmale durchsuchen';

  /**
   * Allows to select a tooltip for each bucket. Defaults to `<value> | <label>`.
   */
  @Input()
  tooltipSelector?: (bucket: TreeFacet.Bucket<TData>) => string;

  @Input()
  insightsEventsConfig: TreeFacetInsightsEventsConfig | undefined = undefined;

  @ViewChild('searchField') searchField?: FilterSearchFieldComponent;

  /**
   * Emits on selection change and provides the selected values of the facet.
   */
  @Output() readonly changed = new EventEmitter<string | undefined>();
  /**
   * Emits on change of the selected tree type and provides the selected value of the type facet.
   */
  @Output() readonly changedType = new EventEmitter<string | undefined>();

  private _facet!: TreeFacet<TData>;
  private _typeFacet?: SingleSelectFacet;
  private readonly _allBuckets$ = new BehaviorSubject<TreeFacet.Bucket<TData>[]>([]);
  private _searchable = false;
  private _selectionKey = `treeFacet${++defaultSelectionKeyCounter}`;
  private _showSelectionInfo = false;
  private _selectionState!: FacetSelectionService.SelectionState;
  private _expansionState!: FacetSelectionService.SelectionState;

  readonly searchControl = new UntypedFormControl('');

  readonly filteredBucketsOptions: Signal<SelectOption<TreeFacet.Bucket<TData>>[]> = toSignal(
    this.getFilteredBucketsOptionsObservable(),
    { initialValue: [] },
  );

  readonly bucketSelectOptions: WritableSignal<SelectOption<ListFacetBase.Bucket<unknown>>[]> = signal<
    SelectOption<ListFacetBase.Bucket<unknown>>[]
  >([]);

  selectedBucket?: TreeFacet.Bucket<TData>;
  selectedTypeBucket?: SingleSelectFacet.Bucket;

  private readonly fallbackSelectionKey: string = `treeFacet${++defaultSelectionKeyCounter}`;

  constructor(
    private readonly selectionService: FacetSelectionService,
    private readonly cdr: ChangeDetectorRef,
    @Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null,
  ) {
    this.searchControl.valueChanges
      .pipe(
        throttleTime(SEARCH_EVENT_MIN_INTERVAL_TIME),
        filter(() => !!this.insightsEventsConfig),
        tap(() => this.insightsEventsTrackingService?.trackEvent(this.insightsEventsConfig!.filterSearch)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  ngOnInit(): void {
    const selectionKey = this.selectionKey ?? this.fallbackSelectionKey;
    this._selectionState = this.selectionService.getSelectionState(selectionKey);
    this._expansionState = this.selectionService.getSelectionState(this.getExpansionStateSelectionKey(selectionKey));

    this.setSelectedFromFacet(this._facet);
  }

  ngOnDestroy(): void {
    if (!this.selectionKey) {
      this.selectionService.deleteSelectionState(this.fallbackSelectionKey);
      this.selectionService.deleteSelectionState(this.getExpansionStateSelectionKey(this.fallbackSelectionKey));
    }
  }

  ngAfterViewChecked(): void {
    const searchFieldInput: MatLegacyInput | undefined = this.searchField?.inputElement;

    if (searchFieldInput && !searchFieldInput.focused && this._expansionState.isSelected(searchFocusedSelection)) {
      this._expansionState.toggleSelected(searchFocusedSelection, false);
      searchFieldInput.focus();
      this.cdr.detectChanges();
    }
  }

  get isExpanded(): boolean {
    return this._expansionState.isSelected(expandedSelection);
  }

  toggleExpanded(expanded?: boolean): void {
    this._expansionState.toggleSelected(expandedSelection, expanded);

    if (this.insightsEventsConfig) {
      const insightsEvent: string = this.isExpanded
        ? this.insightsEventsConfig.filterExpand
        : this.insightsEventsConfig.filterCollapse;
      this.insightsEventsTrackingService?.trackEvent(insightsEvent);
    }
  }

  get isSearchExpanded(): boolean {
    return this._expansionState.isSelected(searchExpandedSelection);
  }

  toggleSearchExpanded(expanded?: boolean): void {
    this._expansionState.toggleSelected(searchExpandedSelection, expanded);
    this._expansionState.toggleSelected(searchFocusedSelection, expanded);
  }

  isBucketExpanded(bucket: TreeFacet.Bucket<TData>): boolean {
    return this._selectionState.isSelected(bucket.value);
  }

  toggleBucketExpanded(bucket: TreeFacet.Bucket<TData>): void {
    this._selectionState.toggleSelected(bucket.value);
    this.cdr.detectChanges();
  }

  handleBucketClick(bucket: TreeFacet.Bucket<TData>): void {
    this.selectedBucket = bucket;

    if (this.insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this.insightsEventsConfig.valueSelect, { value: bucket.label });
    }

    this.changed.emit(bucket.value);
  }

  handleSearchOptionClick(bucket: TreeFacet.Bucket<TData>): void {
    this.toggleSearchExpanded(false);
    this.searchControl.setValue('');
    this.handleBucketClick(bucket);
  }

  handleTypeChange(bucket: SingleSelectFacet.Bucket | undefined): void {
    if (!bucket) {
      return;
    }

    this.selectedTypeBucket = bucket;

    if (this.insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this.insightsEventsConfig.valueTypeSelect, {
        value: bucket.label,
      });
    }

    this.changedType.emit(bucket.value);
  }

  clearSelection(): void {
    this.selectedBucket = undefined;

    if (this.insightsEventsConfig) {
      this.insightsEventsTrackingService?.trackEvent(this.insightsEventsConfig.filterReset);
    }

    this.changed.emit(undefined);
  }

  getSearchResultLabel(bucket: TreeFacet.Bucket<TData>) {
    return bucket.label;
  }

  getDefaultBucketTooltip(bucket: TreeFacet.Bucket<TData>) {
    return `${bucket.value} | ${bucket.label}`;
  }

  compareByVal(a: FacetBase.BucketBase, b: FacetBase.BucketBase): boolean {
    return a.value === b.value;
  }

  private setSelectedTypeFromFacet(facet?: SingleSelectFacet): void {
    this.selectedTypeBucket = facet?.buckets?.find((x) => x.selected === true);
  }

  private setSelectedFromFacet(facet: TreeFacet<TData>): void {
    for (const b of flattenTreeFacetBucket(facet.root)) {
      // Expand only when initial set
      if (b.selected && !this._selectionState.isSet(b.value)) {
        this._selectionState.toggleSelected(b.value, true);
      }
    }

    if (!this._expansionState.isSet(expandedSelection)) {
      this._expansionState.toggleSelected(expandedSelection, true);
    }
  }

  private setTypeFacetSelectOptions(typeFacet: SingleSelectFacet | undefined): void {
    if (!typeFacet) {
      this.bucketSelectOptions.set([]);
      return;
    }

    const mappedBucketSelectOptions: SelectOption<ListFacetBase.Bucket<unknown>>[] = typeFacet.buckets.map(
      (bucket) => ({ label: bucket.label, value: bucket }),
    );

    this.bucketSelectOptions.set(mappedBucketSelectOptions);
  }

  private getExpansionStateSelectionKey(selectionKey: string): string {
    return `${selectionKey}_$$expansion`;
  }

  private getFilteredBucketsOptionsObservable(): Observable<SelectOption<TreeFacet.Bucket<TData>>[]> {
    return combineLatest([
      this._allBuckets$,
      this.searchControl.valueChanges.pipe(startWith(''), debounceTime(250)),
    ]).pipe(
      map(([buckets, value]) => {
        return value ? Array.from(filterBuckets(buckets, value)) : [];
      }),
      map((buckets) => this.mapFilteredBucketsOptions(buckets)),
    );
  }

  private mapFilteredBucketsOptions(buckets: TreeFacet.Bucket<TData>[]): SelectOption<TreeFacet.Bucket<TData>>[] {
    return buckets.map((bucket) => ({
      label: bucket.label,
      value: bucket,
    }));
  }
}
