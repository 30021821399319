<button
  mat-flat-button
  class="action-button"
  [class]="'action-button--' + color()"
  [disabled]="isDisabled()"
  (click)="$event.stopPropagation(); click.emit()"
>
  @if (icon(); as icon) {
    <mat-icon class="action-button__icon material-icons-outlined" [mpIcon]="icon" />
  }

  {{ label() }}
</button>
