<mp-expandable-panel
  class="mp-expandable-panel--no-padding mp-expandable-panel--no-hover"
  [(expanded)]="panelExpanded"
  expandButtonLabel="Zusätzliche Konditionsinformationen"
  [panelHeaderTemplate]="panelHeaderTemplate"
>
  <ng-template>
    <mpctm-condition-signing-info-card-details [conditionInfo]="conditionInfo()" />
  </ng-template>
</mp-expandable-panel>

<ng-template #panelHeaderTemplate>
  <mpctm-condition-signing-info-card-header
    class="info-card-header"
    [conditionInfo]="conditionInfo()"
    (request)="request.emit()"
  />
</ng-template>
