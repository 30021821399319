import { type EnvironmentProviders } from '@angular/core';

import { GhxExportPermissions } from '@mp/integrations/ghx/export/feature';
import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';

const moduleName = 'Ghx';

export function provideGhxFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'GHX',
    }),
    provideModulePermissions(moduleName, GhxExportPermissions),
  ];
}
