import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { PageResponse } from '@core/shared/domain';
import { NotificationService } from '@core/shared/util';
import { CategoryListItem } from '@mp/content-manager/categories/domain';
import { fetch } from '@nrwl/angular';

import { CategoriesService } from '../services';

import { CategoriesActions } from './categories.actions';

@Injectable()
export class CategoriesEffects {
  fetchCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoriesActions.fetchCategories),
      fetch({
        run: ({ searchTerm }) => {
          return this.categoriesService
            .fetchCategoryTypes(searchTerm)
            .pipe(
              map((categoriesPageResponse: PageResponse<CategoryListItem>) =>
                CategoriesActions.fetchCategoriesSuccess({ categoriesPageResponse }),
              ),
            );
        },
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Laden der Warengruppen.');
          return CategoriesActions.fetchCategoriesError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly categoriesService: CategoriesService,
    private readonly notificationService: NotificationService,
  ) {}
}
