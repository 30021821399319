import { permissions } from '@mpk/shared/domain';

enum Amor3ExportPermissionsEnum {
  None = 0,

  /**
   * Use the article export via AMOR3 API.
   */
  UseExport = 1,

  /**
   * Manage the settings for the article export via AMOR3 API.
   */
  ManageSettings = 2,

  /**
   * Use the article export via AMOR3 including price information.
   */
  UseExportWithPrices = 4 | UseExport,
}

export const Amor3ExportPermissions = permissions(Amor3ExportPermissionsEnum, {
  featureId: 'Export',
});
