import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CategoriesState, categoriesFeatureKey, selectAllCategories } from './categories.reducer';

export const selectCategoriesState = createFeatureSelector<CategoriesState>(categoriesFeatureKey);

export const selectCategoriesLoaded = createSelector(selectCategoriesState, (state) => state.categoriesLoaded);

export const selectCategories = createSelector(selectCategoriesState, (state) =>
  selectAllCategories(state.categories).sort(
    (a, b) => a.type.localeCompare(b.type) || b.version.localeCompare(a.version, undefined, { numeric: true }),
  ),
);
