<mpctm-condition-info-section class="mpctm-condition-info-section--no-padding">
  <div class="section-header" condition-info-section-header>
    <span>Konditionsdetails</span>

    <div class="section-header__last-change">
      <div class="last-change-date">
        <span class="last-change-date-label truncate" title="Letzte Änderung:">Letzte Änderung:</span>
        <span class="last-change-date-value">{{ conditionInfo().lastChangeDate | date: 'dd.MM.yyyy' }}</span>
      </div>

      <mpctm-inline-action-button class="section-header__last-change-button" label="Historie" />
    </div>
  </div>

  <mpctm-condition-details [conditionInfo]="conditionInfo()" />
</mpctm-condition-info-section>
