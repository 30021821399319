<mat-card>
  <table mat-table class="api-key-table" [dataSource]="keys">
    <div *matNoDataRow class="no-data-row">Keine API-Keys vorhanden</div>

    <ng-container matColumnDef="key">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-key truncate"
        *matHeaderCellDef
        title="API-Key"
        >API-Key</mat-header-cell
      >
      <mat-cell class="api-key-table__cell api-key-table__cell-key truncate" *matCellDef="let item">
        @if (item.key.apiKey) {
          {{ item.key.apiKey }}
        } @else {
          <span class="new-key">00000000-0000-0000-0000-000000000000</span>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-description truncate"
        *matHeaderCellDef
        title="Description"
        >Beschreibung</mat-header-cell
      >
      <mat-cell
        class="api-key-table__cell api-key-table__cell-description truncate"
        *matCellDef="let item"
        [title]="item.key.description"
      >
        @if (item.editMode) {
          <mat-form-field class="form-field" subscriptSizing="dynamic">
            <input class="input" matInput [(ngModel)]="item.key.description" />
          </mat-form-field>
        } @else {
          {{ item.key.description }}
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="from">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-from truncate"
        *matHeaderCellDef
        title="Aktiv von"
        >Aktiv von</mat-header-cell
      >
      <mat-cell class="api-key-table__cell api-key-table__cell-from truncate" *matCellDef="let item">
        @if (!item.editMode) {
          {{ item.key.activeFrom | date: 'shortDate' }}
        }

        @if (item.editMode) {
          <mat-form-field class="form-field" subscriptSizing="dynamic">
            <input class="input" matInput [matDatepicker]="picker" [(ngModel)]="item.key.activeFrom" />
            <mat-datepicker-toggle matIconSuffix [for]="picker" />
            <mat-datepicker #picker />
          </mat-form-field>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="to">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-to truncate"
        *matHeaderCellDef
        title="Aktive bis"
        >Aktiv bis</mat-header-cell
      >
      <mat-cell class="api-key-table__cell api-key-table__cell-to truncate" *matCellDef="let item">
        @if (!item.editMode) {
          {{ item.key.activeTo | date: 'shortDate' }}
        }

        @if (item.editMode) {
          <mat-form-field class="form-field" subscriptSizing="dynamic">
            <input class="input" matInput [matDatepicker]="picker" [(ngModel)]="item.key.activeTo" />
            <mat-datepicker-toggle matIconSuffix [for]="picker" />
            <mat-datepicker #picker />
          </mat-form-field>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-active truncate"
        *matHeaderCellDef
        title="Aktive"
        >Aktiv</mat-header-cell
      >
      <mat-cell class="api-key-table__cell api-key-table__cell-active truncate" *matCellDef="let item">
        @if (!item.key.isLocked && (item.key.activeFrom | mpkIsValidDate: item.key.activeTo)) {
          @if (item.key.apiKey) {
            <mat-icon class="material-icons-outlined icon icon--success" title="Aktiv">check</mat-icon>
          }
        } @else {
          <mat-icon class="material-icons-outlined icon icon--warn" title="Inaktiv">block</mat-icon>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="locked">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-locked truncate"
        *matHeaderCellDef
        title="Gesperrt"
        >Gesperrt</mat-header-cell
      >
      <mat-cell class="api-key-table__cell api-key-table__cell-locked truncate" *matCellDef="let item">
        @if (item.editMode) {
          <div class="lock">
            <mat-icon class="icon material-icons-outlined" title="Aktiv">lock</mat-icon>
            <mat-checkbox [(ngModel)]="item.key.isLocked" color="accent" />
          </div>
        }
        @if (!item.editMode) {
          @if (item.key.isLocked) {
            <mat-icon class="material-icons-outlined icon" title="Aktiv">lock</mat-icon>
          }
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell
        class="api-key-table__header-cell api-key-table__header-cell-actions truncate"
        *matHeaderCellDef
        title="Aktionen"
        >Aktionen</mat-header-cell
      >
      <mat-cell class="api-key-table__cell api-key-table__cell-actions" *matCellDef="let item">
        @if (!item.editMode) {
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEdit(item)">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
              <span>Bearbeiten</span>
            </button>
            <button mat-menu-item (click)="onDelete(item)">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
              <span>Löschen</span>
            </button>
          </mat-menu>
        } @else {
          <div class="actions">
            <button mat-flat-button color="accent" (click)="onSave(item)">
              <mat-icon>check</mat-icon>
              Speichern
            </button>
            <button mat-icon-button (click)="onCancel(item)">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row class="api-key-table__header-row" *matHeaderRowDef="cols" />
    <mat-row
      class="api-key-table__row"
      *matRowDef="let row; columns: cols"
      [class]="{ 'api-key-table__row--edit-mode': row.editMode }"
    />
  </table>
</mat-card>
