import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';

import { ConditionAttachment } from '@mp/contract-management/condition-signing-details/domain';

import { NoDataMessageComponent } from '../no-data-message/no-data-message.component';

@Component({
  selector: 'mpctm-condition-attachments-list',
  standalone: true,
  templateUrl: './condition-attachments-list.component.html',
  styleUrl: './condition-attachments-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, LetDirective, MatIconModule, MatButtonModule, MatMenuModule, NoDataMessageComponent],
})
export class ConditionAttachmentsListComponent {
  readonly attachments: InputSignal<ConditionAttachment[]> = input.required<ConditionAttachment[]>();
}
