import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { FilterInputOptionsComponent, FilterInputOptionsData, FilterItem, FilterSection } from '@core/ui';
import { AddressType, addressTypeDescriptions } from '@mp/organization-master-data/addresses/domain';
import { ADDRESS_TYPES_PRIORITY_ORDER } from '@mp/organization-master-data/addresses/util';

export const ADDRESS_TYPE_FILTER_RECORD_KEY = 'address-type-filter';

@Component({
  selector: 'mpomd-address-list-filter',
  standalone: true,
  templateUrl: './address-list-filter.component.html',
  styleUrl: './address-list-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FilterInputOptionsComponent],
})
export class AddressListFilterComponent {
  readonly hidden: InputSignal<boolean> = input<boolean>(false);

  readonly filterChange: OutputEmitterRef<Record<string, AddressType[]>> =
    output<Record<string, Array<AddressType>>>();

  private readonly _options: FilterItem<AddressType>[] = ADDRESS_TYPES_PRIORITY_ORDER.map((option) => {
    return {
      value: option,
      label: addressTypeDescriptions[option],
      disabled: false,
      selected: false,
      key: option,
    };
  });

  protected readonly filterSections: FilterSection<AddressType, FilterInputOptionsData>[] = [
    {
      key: ADDRESS_TYPE_FILTER_RECORD_KEY,
      label: 'Adresstyp',
      options: this._options,
      multiselect: true,
      icon: 'filter_alt',
    },
  ];
}
