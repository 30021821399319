import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InputSignal,
  OutputEmitterRef,
  OutputRef,
  Signal,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { outputFromObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subject, debounce, interval, race } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

type SearchPosition = 'left' | 'right';

const SEARCH_DEBOUNCE_TIME = 500;

@Component({
  selector: 'mp-search-field',
  standalone: true,
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss',
  host: {
    'class': 'mp-search-field',
    '[class.mp-search=field--disabled]': 'disabled()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule],
})
export class SearchFieldComponent {
  readonly placeholder: InputSignal<string> = input<string>('Durchsuchen');

  readonly disabled: InputSignal<boolean> = input<boolean>(false);

  readonly searchTerm: InputSignal<string> = input<string>('');

  readonly showSearch: InputSignal<boolean> = input<boolean>(true);
  readonly searchPosition: InputSignal<SearchPosition> = input<SearchPosition>('left');
  readonly searchDisabled: InputSignal<boolean> = input<boolean>(false);

  readonly showClear: InputSignal<boolean> = input<boolean>(true);

  protected readonly searchField: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  readonly cleared: OutputEmitterRef<void> = output<void>();
  readonly searched: OutputEmitterRef<string> = output<string>();

  readonly valueChange: OutputRef<string> = outputFromObservable(this.searchField.valueChanges);
  readonly searchTermChange: OutputRef<string> = outputFromObservable(this.getSearchTermChangeObservable());

  private readonly immediateSearch$: Subject<void> = new Subject<void>();

  private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public searchTermValue: Signal<string> = toSignal(this.searchField.valueChanges.pipe(map((value) => value.trim())), {
    initialValue: '',
  });

  constructor() {
    effect(() => {
      const disabled: boolean = this.disabled();

      if (disabled !== this.searchField.disabled) {
        this.searchField[disabled ? 'disable' : 'enable']({ emitEvent: false });
        this.cdr.markForCheck();
      }
    });

    effect(() => {
      const value: string = this.searchTerm();

      this.searchField.setValue(value, { emitEvent: false });
    });
  }

  search(): void {
    const searchTerm: string = this.searchField.value;
    this.immediateSearch$.next();
    this.searched.emit(searchTerm);
  }

  clear(): void {
    this.searchField.setValue('');
    this.immediateSearch$.next();
    this.cleared.emit();
  }

  private getSearchTermChangeObservable(): Observable<string> {
    return this.searchField.valueChanges.pipe(
      // Normally search term changes are debounced, but if the user presses enter for example, the search should run immediately.
      debounce(() => race(interval(SEARCH_DEBOUNCE_TIME), this.immediateSearch$)),
      map((value) => value.trim()),
      distinctUntilChanged(),
    );
  }
}
