import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'mpctm-selected-graduation',
  standalone: true,
  templateUrl: './selected-graduation.component.html',
  styleUrl: './selected-graduation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, LetDirective],
})
export class SelectedGraduationComponent {
  readonly selectedGraduationLabel: InputSignal<string> = input.required<string>();

  readonly validUntil: InputSignal<string | undefined> = input<string>();
}
