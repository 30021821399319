import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { DialogHeaderComponent } from '@core/ui';
import { ConditionActionButtonComponent } from '@mp/contract-management/common/ui';
import { ActiveUserComponent } from '@mpk/shared/ui';

import { RequestDialogData } from './request-dialog-data';

@Component({
  selector: 'mpctm-request-dialog',
  standalone: true,
  templateUrl: './request-dialog.component.html',
  styleUrl: './request-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,

    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,

    ActiveUserComponent,
    ConditionActionButtonComponent,
    DialogHeaderComponent,
  ],
})
export class RequestDialogComponent {
  readonly data: RequestDialogData = inject<RequestDialogData>(MAT_DIALOG_DATA);

  private readonly dialogRef: MatDialogRef<RequestDialogComponent> = inject(MatDialogRef<RequestDialogComponent>);

  protected requestText = '';

  closeDialog(): void {
    this.dialogRef.close();
  }

  sendRequest(requestText: string): void {
    this.dialogRef.close(requestText);
  }
}
