<mp-root-module-nav *mpHeaderElement="'left'" label="Kliniklieferanten" [rootModuleLink]="['../']" />

<mp-overview
  moduleTitle="Kliniklieferanten"
  [isCustomHeader]="true"
  [resultCount]="resultCount()"
  (search)="onSearch($event)"
>
  <mp-export-button
    title-additional-content
    class="export-button"
    disabledStateTooltipText="Du hast nicht das benötigte Recht."
    [isProcessing]="isProcessingExport()"
    (clicked)="onExportAll()"
  />

  <button mat-menu-item routerLink="create" disabled>Neu</button>

  <!-- height="864px" -->
  <mp-infinite-scroll
    [itemHeight]="86"
    [itemTemplate]="itemTemplate"
    [dataSource]="dataSource()"
    [scrollThresholdShare]="1"
  />

  <ng-template #itemTemplate let-clinicSupplier>
    <mp-overview-item [path]="clinicSupplier.supplierId">
      <div class="overview-item-wrapper">
        <mpsm-supplier-icon [supplier]="clinicSupplier" />
        <div>
          <h2 class="clinic-supplier-name truncate" [title]="clinicSupplier.name">{{ clinicSupplier.name }}</h2>
          <p class="clinic-supplier-external-number truncate" [title]="clinicSupplier.externalNumber">
            {{ clinicSupplier.externalNumber }}
          </p>

          @if (clinicSupplier.parentSupplier) {
            <p class="clinic-supplier-mapping">
              <mat-icon class="clinic-supplier-mapping-icon">link</mat-icon>
              <span class="truncate" [title]="clinicSupplier.parentSupplier.name">
                {{ clinicSupplier.parentSupplier.name }}
              </span>
            </p>
          }
        </div>
      </div>
    </mp-overview-item>
  </ng-template>
</mp-overview>
