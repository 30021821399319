<div class="graduation">
  <!-- TODO - what if there are more graduation names? -->
  <div class="graduation__wrapper">
    @for (graduationName of conditionGraduation().graduationNames; track index; let index = $index) {
      <div class="graduation__wrapper-column">
        <span class="graduation__name">{{ graduationName }}</span>

        <div class="graduation__levels">
          @for (graduationLevel of conditionGraduation().levels || []; track $index) {
            @if (graduationLevel.requirements?.[index]; as requirement) {
              @if (requirement) {
                <span
                  *ngrxLet="requirement | mpGraduationValueOutput as requirementLabel"
                  class="graduation-requirement-value truncate"
                  [title]="'ab ' + requirementLabel"
                >
                  <span class="graduation-requirement-value__prefix">{{ index > 0 ? 'und ab ' : 'ab ' }}</span>
                  {{ requirementLabel }}
                </span>
              }
            }
          }
        </div>
      </div>
    }
  </div>

  @if (conditionGraduation().labels; as graduationLabels) {
    <div class="graduation__labels">
      @for (label of graduationLabels | keyvalue; track $index) {
        <div class="graduation__label">
          <span class="graduation__label-key">{{ label.key }}: </span>
          <span class="graduation__label-value">{{ label.value }}</span>
        </div>
      }
    </div>
  }
</div>
