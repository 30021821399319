<h2 class="dialog-title" matDialogTitle>Wähle den Dateninhalt der Daten aus</h2>

<div class="dialog-content" matDialogContent>
  <div class="dialog-content__options">
    @for (filetype of fileTypeOptions; track filetype) {
      <div
        class="option"
        [class]="{
          'option--selected': isSelected(filetype)
        }"
        (click)="fileInfo.fileType = filetype"
      >
        <mpcm-file-type-tile
          class="option__file-type-tile"
          [class]="{
            'mpcm-file-type-tile--active': isSelected(filetype)
          }"
          [fileType]="filetype"
        />
        <mat-radio-button class="option__radio-button" [checked]="isSelected(filetype)" />
        <span
          class="option__label truncate"
          [class.option__label--active]="isSelected(filetype)"
          [title]="filetype | mpcmFileTypeName"
          >{{ filetype | mpcmFileTypeName }}</span
        >
      </div>
    }
  </div>

  <mat-form-field class="dialog-content__comment" subscriptSizing="dynamic">
    <mat-label class="dialog-content__form-label">Deine Bemerkungen (Optional)</mat-label>
    <textarea matInput cdkAutosizeMinRows="4" cdkTextareaAutosize [(ngModel)]="fileInfo.comment"></textarea>
  </mat-form-field>
</div>

<div class="dialog-actions" matDialogActions [align]="'center'">
  <button
    mat-flat-button
    class="dialog-submit"
    color="accent"
    [matDialogClose]="fileInfo"
    [disabled]="!fileInfo.fileType"
  >
    Upload starten
  </button>
</div>
