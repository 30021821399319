import { type EnvironmentInjector, createEnvironmentInjector } from '@angular/core';

import { type LazyComponentDetails } from '@core/shared/util';
import { type ComponentPluginComponent } from '@mp/shared/component-plugins/util';
import type { CurrentOrganizationInfoTabPlugin, CurrentOrganizationInfoTabPluginInput } from '@mpk/shared/util';

export function createClinicCatalogInfoTabPlugin(): CurrentOrganizationInfoTabPlugin {
  return {
    label: 'Klinikkatalog',

    async getComponentDetails(
      parentInjector: EnvironmentInjector,
    ): Promise<LazyComponentDetails<ComponentPluginComponent<CurrentOrganizationInfoTabPluginInput>>> {
      const { ClinicCatalogInfoComponent } = await import('../components');
      const { ClinicCatalogService } = await import('@mp/content-manager/klinikkataloge/data-access');

      return {
        component: ClinicCatalogInfoComponent,
        environmentInjector: createEnvironmentInjector([ClinicCatalogService], parentInjector),
      };
    },
  };
}
