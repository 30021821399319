<mat-form-field class="search-field-form-field" appearance="outline" subscriptSizing="dynamic">
  <input matInput type="text" [placeholder]="placeholder()" [formControl]="searchField" (keyup.enter)="search()" />

  @if (showSearch() && searchPosition() === 'left') {
    <ng-container matPrefix>
      <ng-template [ngTemplateOutlet]="searchButton" />
    </ng-container>
  }

  <ng-container matSuffix>
    @if (showClear() && searchField.value.length > 0) {
      <button class="action-button" mat-icon-button [disabled]="disabled()" (click)="clear()">
        <mat-icon>clear</mat-icon>
      </button>
    }

    @if (showSearch() && searchPosition() === 'right') {
      <ng-template [ngTemplateOutlet]="searchButton" />
    }
  </ng-container>
</mat-form-field>

<ng-template #searchButton>
  <button class="action-button" mat-icon-button [disabled]="searchDisabled() || disabled()" (click)="search()">
    <mat-icon>search</mat-icon>
  </button>
</ng-template>
