import { Injectable, Signal, computed } from '@angular/core';

import { ActiveOrganizationService, AuthService, ProfileFacade } from '@mpauth/shared/data-access';
import { UserProfileData } from '@mpauth/shared/domain';
import { AbstractAuthInfoProviderService, AuthInfo } from '@mpk/shared/data-access';
import { OrganizationContext, UserContext, UserType } from '@mpk/shared/domain';

/**
 * The implementation of AbstractAuthInfoProviderService that provides the basic auth info
 * for the current user.
 */
@Injectable()
export class AuthInfoProviderService extends AbstractAuthInfoProviderService {
  override authInfo: Signal<AuthInfo | null> = computed(() => {
    const accessToken = this.authService.getAccessToken();
    const profile = this.profileFacade.profile();

    if (accessToken && profile) {
      const { id: userId, name, activeOrganization, permissions, userData } = profile;

      // This will not happen.
      if (!activeOrganization) {
        return null;
      }

      return Object.freeze<AuthInfo>({
        userContext: Object.freeze<UserContext>(this.getUserContextByUserData(userId, name, userData)),
        organizationContext: Object.freeze<OrganizationContext>({
          organizationId: activeOrganization.organizationId,
          name: activeOrganization.name,
        }),
        accessToken,
        permissions,
      });
    }

    return null;
  });

  constructor(
    private readonly authService: AuthService,
    private readonly activeOrganizationService: ActiveOrganizationService,
    private readonly profileFacade: ProfileFacade,
  ) {
    super();
  }

  scheduleLogout(): void {
    this.activeOrganizationService.resetActiveOrganizationIdOnLogout();
    this.authService.logout();
  }

  private getUserContextByUserData(userId: string, name: string, userData: UserProfileData): UserContext {
    switch (userData.type) {
      case UserType.IdentityUser:
        return {
          userId,
          name,
          type: userData.type,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        };
      default:
        return {
          userId,
          name,
          type: userData.type,
        };
    }
  }
}
