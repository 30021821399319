import { AfterContentChecked, Directive, ElementRef, contentChild, contentChildren, input } from '@angular/core';
import { type ValidationErrors } from '@angular/forms';
import { MatFormFieldControl, MatLabel } from '@angular/material/form-field';

import { ValidationErrorDirective } from './validation-error.directive';

type ValidationErrorMessageTemplates = Record<string, string | ((values: object) => string)>;

function tagged(strings: TemplateStringsArray, ...keys: string[]) {
  return (values: Record<string, unknown>): string => {
    let i = 0;
    const result = [strings[i++]];

    for (const key of keys) {
      result.push(`${values[key]}`, strings[i++]);
    }

    return result.join('');
  };
}

const defaultMessages: Record<string, (values: Record<string, unknown>) => string> = {
  server: tagged`${'msg'}`,
  required: tagged`${'_'} muss angegeben werden.`,
  min: tagged`${'_'} muss größer oder gleich ${'min'} sein.`,
  max: tagged`${'_'} muss kleiner oder gleich ${'max'} sein.`,
  minlength: tagged`${'_'} muss mindestens ${'requiredLength'} Zeichen lang sein.`,
  maxlength: tagged`${'_'} darf maximal ${'requiredLength'} Zeichen lang sein.`,
  email: tagged`${'_'} ist keine gültige E-Mail-Adresse.`,
  url: tagged`${'_'} ist keine gültige URL.`,
};

/**
 * A mat-form-field that automatically displays the first validation error in its elements
 * that have the directive `[mpValidationError]` set.
 */
@Directive({
  selector: 'mat-form-field[mpAutoValidationErrors]',
  standalone: true,
})
export class AutoValidationErrorsDirective implements AfterContentChecked {
  private readonly formFieldControl = contentChild.required(MatFormFieldControl);

  private readonly labelChild = contentChild(MatLabel, { read: ElementRef });

  private readonly directiveErrorChildren = contentChildren(ValidationErrorDirective, { descendants: true });

  /**
   * A mat-form-field that automatically displays the first validation error in its elements
   * that have the directive `[mpValidationError]` set.
   *
   * Custom validation error message templates.
   */
  public readonly validationMessages = input<ValidationErrorMessageTemplates, ValidationErrorMessageTemplates | ''>(
    {},
    {
      alias: 'mpAutoValidationErrors',
      transform: (input) => input || {}, // allow empty string which is the value if only the directive without assignment is set
    },
  );

  /**
   * Custom label for the error messages.
   */
  public readonly validationErrorlabel = input<string>('');

  ngAfterContentChecked(): void {
    this.updateErrorElements(this.getFirstError(this.formFieldControl().ngControl?.errors) || '');
  }

  private updateErrorElements(error: string): void {
    for (const { el, targetProperty } of this.directiveErrorChildren()) {
      el.nativeElement[targetProperty()] = error;
    }
  }

  private getFirstError(validationErrors?: ValidationErrors | null): string | null {
    if (validationErrors) {
      // Use server error first (manually set)
      let err = validationErrors['server'];

      if (err) {
        return this.formatMessage('server', err);
      }

      // Then required
      err = validationErrors['required'];

      if (err) {
        return this.formatMessage('required', err);
      }

      // Otherwise the first in place
      for (const [name, err] of Object.entries(validationErrors)) {
        if (err) {
          return this.formatMessage(name, err);
        }
      }
    }

    return null;
  }

  private formatMessage(key: string, err: object): string {
    const msg = this.validationMessages()[key] ?? defaultMessages[key] ?? key;

    if (typeof msg === 'function') {
      return msg({
        ...err,
        _: this.getLabel() || 'Der Wert',
      });
    }

    return msg || `${key}`;
  }

  private getLabel(): string {
    return this.validationErrorlabel() || this.labelChild()?.nativeElement.innerText || '';
  }
}
