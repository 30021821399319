import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [HttpClientModule],
})
export class IconRegistryModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    // https://materialdesignicons.com
    this.matIconRegistry.addSvgIconSetInNamespace(
      'mdi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'barcode',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/barcode.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'export_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/export_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'globalindustryarticle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/globalindustryarticle.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'globalclinicarticle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/globalclinicarticle.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'division',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/suppliertype_division.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'ref_number',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/ref_number.svg'),
    );

    const statuses = ['successful', 'pending', 'failed'];

    statuses.forEach((status) => {
      this.matIconRegistry.addSvgIcon(
        `export_${status}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/svg/export_${status}.svg`),
      );
    });

    this.matIconRegistry.addSvgIcon(
      'transport_clinic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/transport_clinic.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'truck_half',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/truck_half.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'truck_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/truck_full.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'delivered',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/delivered.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'approval_indicator',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/approval_indicator.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'home_pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/home_pin.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'main_supplier',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/main_supplier.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'enrich',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/enrich.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'dotted-square',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/dotted-square.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'inactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/inactive.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'document_edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/document_edit.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'global_document',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/global_document.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'global_document_edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/global_document_edit.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'global_document_edit_disabled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/global_document_edit_disabled.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'price_signed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/price_signed.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'price_notsigned',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/price_notsigned.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'filter_toggle_open',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/filter_toggle_open.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'filter_toggle_close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/filter_toggle_close.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'archived',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/archived.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'contract',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contract.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'order',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/order.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'lightbulb',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/lightbulb.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'contract_data',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/contract_data.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'global_article_toggle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/global_article_toggle.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'global_article_star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/global_article_star.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'pharmacy_custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/pharmacy_custom.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'node_leaf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/node_leaf.svg'),
    );
  }
}
