<mp-overview moduleTitle="Benutzer" [resultCount]="users()?.length ?? 0" (search)="onSearch($event)">
  <ng-container ngProjectAs="[title-additional-content]">
    <ng-content select="[export-button]" />
  </ng-container>

  <button mat-menu-item routerLink="create">Neu</button>

  @if (usersLoaded()) {
    <cdk-virtual-scroll-viewport [itemSize]="itemSize">
      <mp-overview-item *cdkVirtualFor="let user of users(); trackBy: trackByFn" [path]="user.userId">
        <div class="overview-item-wrapper">
          <mp-avatar
            class="overview-item-wrapper__avatar"
            [image]="user.image | mpkUserImage"
            [name]="user.name"
            size="small"
          />

          <div class="overview-item-wrapper__user">
            <h2 class="overview-item-wrapper__user-name">{{ user.name }}</h2>
            <ng-template [ngTemplateOutlet]="userDetailsTemplate" [ngTemplateOutletContext]="{ $implicit: user }" />
          </div>

          @if (user.blocked.isBlocked || !user.isActivated) {
            <mpk-user-account-status-icon
              class="overview-item-wrapper__account-status"
              [userAccountStatus]="{
                isActivated: user.isActivated,
                isBlocked: user.blocked.isBlocked
              }"
            />
          }
        </div>
      </mp-overview-item>
    </cdk-virtual-scroll-viewport>
  } @else {
    <mp-spinner />
  }
</mp-overview>
