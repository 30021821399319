import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  InputSignal,
  OutputEmitterRef,
  TemplateRef,
  effect,
  input,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

import { TemplateContext } from '@core/shared/util';
import { AvatarComponent, OverviewModule, SpinnerComponent } from '@core/ui';
import { IdentityUserListItem } from '@mp/kernel/users/domain';
import { UserImagePipe } from '@mp/kernel/users/util';

import { UserAccountStatusIconComponent } from '../user-account-status-icon/user-account-status-icon.component';

@Component({
  selector: 'mpk-users-list-view',
  standalone: true,
  templateUrl: './users-list-view.component.html',
  styleUrls: ['./users-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    ScrollingModule,

    MatIconModule,
    MatMenuModule,

    AvatarComponent,
    OverviewModule,
    SpinnerComponent,
    UserImagePipe,
    UserAccountStatusIconComponent,
  ],
})
export class UsersListViewComponent {
  private readonly scrollIntoViewUserId = signal<string | null>(null);

  protected readonly itemSize = 100;

  readonly users: InputSignal<IdentityUserListItem[]> = input.required<IdentityUserListItem[]>();

  readonly usersLoaded: InputSignal<boolean> = input.required<boolean>();

  readonly loadUsers: OutputEmitterRef<string | undefined> = output<string | undefined>();

  @ContentChild(TemplateRef)
  userDetailsTemplate!: TemplateRef<TemplateContext<IdentityUserListItem>>;

  protected readonly scroller = viewChild(CdkVirtualScrollViewport);

  constructor() {
    effect((onCleanup) => {
      const scrollIntoViewUserId = this.scrollIntoViewUserId();
      const scroller = this.scroller();

      if (scrollIntoViewUserId && scroller && this.usersLoaded()) {
        const viewportSize = scroller.getViewportSize();

        // Viewport is not yet rendered, wait for the first "tick".
        if (viewportSize === 0) {
          const subscription = scroller.scrolledIndexChange.subscribe(() => {
            subscription.unsubscribe();

            this.doScrollIntoView(scrollIntoViewUserId);
          });

          onCleanup(() => subscription.unsubscribe());

          return;
        }

        this.doScrollIntoView(scrollIntoViewUserId);
      }
    });
  }

  tryScrollIntoView(userId: string): void {
    this.scrollIntoViewUserId.set(userId);
  }

  onSearch(searchTerm: string | undefined): void {
    this.loadUsers.emit(searchTerm);
  }

  trackByFn(_index: number, { userId }: IdentityUserListItem): string {
    return userId;
  }

  private doScrollIntoView(userId: string): void {
    const scroller = this.scroller();

    if (!scroller) {
      return;
    }

    const index = this.users().findIndex((user) => user.userId === userId);

    if (index < 0) {
      return;
    }

    // reset
    untracked(() => this.scrollIntoViewUserId.set(null));

    // Check if item is already visible. Do nothing in this case.
    const itemOffset = index * this.itemSize;
    const currentOffset = scroller.measureScrollOffset();
    const viewportSize = scroller.getViewportSize();

    if (itemOffset >= currentOffset && itemOffset + this.itemSize <= currentOffset + viewportSize) {
      return;
    }

    scroller.scrollToOffset(itemOffset);
  }
}
