import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'mpctm-inline-action-button',
  standalone: true,
  templateUrl: './inline-action-button.component.html',
  styleUrl: './inline-action-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule],
})
export class InlineActionButtonComponent {
  readonly label: InputSignal<string> = input.required<string>();
}
