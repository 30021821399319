<span class="selection-label font-bold" title="Ausgewählt">Ausgewählt:</span>

<span class="selected-graduation-label font-bold truncate" [title]="selectedGraduationLabel()">
  {{ selectedGraduationLabel() }}
</span>

@if (validUntil(); as validUntil) {
  <span
    class="validity-date truncate"
    *ngrxLet="'(Gültig bis ' + (validUntil | date: 'dd.MM.yyyy') + ')' as validUntilDate"
    [title]="validUntilDate"
  >
    {{ validUntilDate }}</span
  >
}
