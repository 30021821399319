@if (conditionGraduations(); as conditionGraduations) {
  @if (conditionGraduations.baseCondition; as baseCondition) {
    <mpctm-condition-graduation-wrapper class="graduation-wrapper base-condition-graduation" label="Basispreis">
      <mpctm-condition-graduation
        class="mpcm-condition-graduation--no-top-padding"
        [conditionGraduation]="getBaseConditionGraduation(conditionGraduations.baseConditionName, baseCondition)"
      />
    </mpctm-condition-graduation-wrapper>
  }

  @if (conditionGraduations.graduations?.length) {
    <mpctm-condition-graduation-wrapper class="graduation-wrapper" label="Staffelungen">
      <div class="graduation-wrapper__graduations">
        @for (graduation of conditionGraduations.graduations; let isFirst = $first; track $index) {
          <mpctm-condition-graduation
            class="condition-graduation"
            [class]="{ 'mpcm-condition-graduation--no-top-padding': isFirst }"
            [conditionGraduation]="graduation"
          />
        }
      </div>
    </mpctm-condition-graduation-wrapper>
  }
} @else {
  <!-- TODO - can there be no graduations at all? -->
  <mpctm-no-data-message class="no-data-message" message="Es gibt keine Staffelungen für diese Kondition" />
}
