import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { Graduation, GraduationLevel } from '@core/ui';
import {
  ConditionGraduationLevel,
  ConditionGraduations,
} from '@mp/contract-management/condition-details/tab-graduations/domain';

import { NoDataMessageComponent } from '../../../no-data-message/no-data-message.component';
import { ConditionGraduationWrapperComponent } from '../condition-graduation-wrapper/condition-graduation-wrapper.component';
import { ConditionGraduationComponent } from '../condition-graduation/condition-graduation.component';

@Component({
  selector: 'mpctm-condition-graduations',
  standalone: true,
  templateUrl: './condition-graduations.component.html',
  styleUrl: './condition-graduations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ConditionGraduationComponent, ConditionGraduationWrapperComponent, NoDataMessageComponent],
})
export class ConditionGraduationsComponent {
  readonly conditionGraduations: InputSignal<ConditionGraduations | null> =
    input.required<ConditionGraduations | null>();

  getBaseConditionGraduation(
    baseConditionName: string | undefined,
    baseCondition: ConditionGraduationLevel,
  ): Graduation {
    return {
      graduationNames: [baseConditionName || ''],
      levels: this.mapConditionGraduationLevels([baseCondition]),
    };
  }

  // TODO - copied from condition-graduation-info-utils.ts - should be adjusted after models are known
  private mapConditionGraduationLevels(levels: ConditionGraduationLevel[] | undefined): GraduationLevel[] {
    if (!levels) {
      return [];
    }

    return levels.map(({ requirements, description }) => ({
      requirements,
      description: description ?? undefined,
    }));
  }
}
