import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, HostBinding, Signal, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

import { HeaderElementDirective } from '@core/shared/util';
import { OverviewModule, RootModuleNavComponent, SpinnerComponent } from '@core/ui';
import { AddressListItem } from '@mp/organization-master-data/addresses/domain';
import { AddressListFilterComponent } from '@mp/organization-master-data/addresses/ui';

import { AddressChipContentPipe } from './address-chip-content.pipe';
import { AddressesListStore } from './addresses-list.store';

@Component({
  selector: 'mpomd-addresses-list',
  standalone: true,
  templateUrl: './addresses-list.component.html',
  styleUrl: './addresses-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,

    MatMenuModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,

    ScrollingModule,
    OverviewModule,
    SpinnerComponent,
    HeaderElementDirective,
    RootModuleNavComponent,
    AddressListFilterComponent,
    AddressChipContentPipe,
  ],
  providers: [AddressesListStore],
})
export class AddressesListComponent {
  @HostBinding('class') className = 'mpomd-addresses-list';

  protected readonly store: AddressesListStore = inject(AddressesListStore);

  protected readonly addresses: Signal<AddressListItem[]> = this.store.addresses;
  protected readonly addressesLoaded: Signal<boolean> = this.store.addressesLoaded;
  protected readonly filterVisible: Signal<boolean> = this.store.filterVisible;

  constructor() {
    this.store.fetchAddresses();
  }

  trackByFn(_index: number, { addressId }: AddressListItem): string {
    return addressId;
  }
}
