<span class="label">{{ label() }}</span>

<div class="content">
  <div class="content__main truncate">
    <ng-content />
  </div>
  <div class="content__context-info truncate">
    <ng-content select="[condition-param-context-info]" />
  </div>
</div>
