import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { RequestDialogComponent } from './request-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'mpctm-request-dialog-container',
};

@NgModule({
  imports: [RequestDialogComponent],
  exports: [RequestDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class RequestDialogModule {}
