import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { IdentityUser } from '@mp/kernel/users/domain';

@Component({
  selector: 'mpk-user-assignments-table',
  standalone: true,
  templateUrl: './user-assignments-table.component.html',
  styleUrl: './user-assignments-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatTableModule, MatIconModule],
})
export class UserAssignmentsTableComponent {
  readonly users: InputSignal<IdentityUser[]> = input.required<IdentityUser[]>();

  readonly removeUser: OutputEmitterRef<{ userId: string; name: string }> = output<{
    userId: string;
    name: string;
  }>();

  protected readonly displayedCols: string[] = ['name', 'email', 'actions'];

  removeUserAssignment(userId: string, name: string): void {
    this.removeUser.emit({ userId, name });
  }

  trackByFn(_index: number, { userId }: IdentityUser): string {
    return userId;
  }
}
