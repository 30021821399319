@if (condition().signStatus === SignStatus.SIGNED) {
  <mpctm-signed-condition-footer
    [signedBy]="condition().signedBy ?? 'Unbekannt'"
    [dateOfSigning]="condition().dateOfSigning ?? 'Unbekannt'"
  />
} @else if (condition().signStatus === SignStatus.UNSIGNED) {
  @if (selectedGraduationLabel(); as selectedGraduationLabel) {
    <mpctm-condition-selected-graduation-footer
      [selectedGraduationLabel]="selectedGraduationLabel"
      [requireAdditionalInfo]="!isSelectedGraduationEKGPrice()"
      (signCondition)="sign.emit(condition())"
      (requestConfirmation)="requestConfirmation.emit()"
    />
  }
} @else if (condition().signStatus === SignStatus.PENDING_CONFIRMATION) {
  <mpctm-condition-pending-confirmation-footer
    [creator]="condition().signedBy ?? 'Unbekannt'"
    [requestDate]="condition().dateOfSigning ?? 'Unbekannt'"
    (accept)="sign.emit(condition())"
    (reject)="reject.emit()"
  />
}
