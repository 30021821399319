import { ChangeDetectionStrategy, Component, Signal, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { DialogHeaderComponent, FileUploadLabelComponent, UploadFormComponent, UploadFormStatus } from '@core/ui';
import { SignStatus, signStatusDescriptions } from '@mp/contract-management/common/domain';
import {
  ConditionActionButtonComponent,
  ConditionSignStatusComponent,
  EntityNumberChipComponent,
} from '@mp/contract-management/common/ui';

import { ConditionAttachmentsListComponent } from '../condition-attachments-list/condition-attachments-list.component';

import { ChangeConditionStatusDialogData } from './change-condition-status-dialog-data';

@Component({
  selector: 'mpctm-change-condition-status-dialog',
  standalone: true,
  templateUrl: './change-condition-status-dialog.component.html',
  styleUrl: './change-condition-status-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatDialogModule,

    ConditionSignStatusComponent,
    ConditionAttachmentsListComponent,
    ConditionActionButtonComponent,
    FileUploadLabelComponent,
    UploadFormComponent,
    EntityNumberChipComponent,
    DialogHeaderComponent,
  ],
})
export class ChangeConditionStatusDialogComponent {
  readonly data: ChangeConditionStatusDialogData = inject<ChangeConditionStatusDialogData>(MAT_DIALOG_DATA);

  readonly SignStatus = SignStatus;
  readonly UploadFormStatus = UploadFormStatus;

  protected readonly buttonLabel: Signal<string> = computed(() => {
    return `Zu '${signStatusDescriptions[SignStatus.SIGNED]}' ändern`;
  });

  private readonly dialogRef: MatDialogRef<ChangeConditionStatusDialogComponent> = inject(
    MatDialogRef<ChangeConditionStatusDialogComponent>,
  );

  closeDialog(): void {
    this.dialogRef.close();
  }

  signCondition(): void {
    this.dialogRef.close(true);
  }
}
