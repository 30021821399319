import { AddressListItem } from '@mp/organization-master-data/addresses/domain';
import { ADDRESS_TYPES_PRIORITY_ORDER } from '@mp/organization-master-data/addresses/util';

export function sortAddresses(addresses: AddressListItem[]): AddressListItem[] {
  return [...addresses].sort((a, b) => {
    const priorityA: number = getAddressPriority(a.addressTypes);
    const priorityB: number = getAddressPriority(b.addressTypes);

    return priorityB - priorityA;
  });
}

function getAddressPriority(addressTypes: string[] | null): number {
  if (!addressTypes) return 0;

  const index = ADDRESS_TYPES_PRIORITY_ORDER.findIndex((addressType) => addressTypes.includes(addressType));
  return index === -1 ? 0 : ADDRESS_TYPES_PRIORITY_ORDER.length - index;
}
