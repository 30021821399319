<mpctm-page-footer>
  <mpctm-condition-request-generation-info class="truncate" [requestDate]="requestDate()" [creator]="creator()" />

  <!-- TODO Are there any other actions or messages? -->
  <div class="request-actions-wrapper truncate">
    <span class="request-actions-wrapper__info truncate" title="Anfrage wurde vom Lieferanten">
      Anfrage wurde vom Lieferanten
    </span>

    <div class="request-actions-wrapper__actions">
      <mpctm-condition-action-button color="success" icon="draw" label="Angenommen" (click)="accept.emit()" />
      <mpctm-condition-action-button color="error" icon="close" label="Abgelehnt" (click)="reject.emit()" />
    </div>
  </div>
</mpctm-page-footer>
