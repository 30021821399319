import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { UtilPipesModule } from '@core/shared/util';

import { SearchFieldComponent } from '../../search-field/search-field.component';

@Component({
  selector: 'mp-entity-list-head',
  standalone: true,
  templateUrl: './entity-list-head.component.html',
  styleUrl: './entity-list-head.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SearchFieldComponent, UtilPipesModule],
})
export class EntityListHeadComponent {
  @ViewChild(SearchFieldComponent) searchField?: SearchFieldComponent;

  @Input() searchTerm = '';

  @Input()
  get showSearch() {
    return this._showSearch;
  }
  set showSearch(value: BooleanInput) {
    this._showSearch = coerceBooleanProperty(value);
  }
  private _showSearch = true;

  @Input()
  get editMode() {
    return this._editMode;
  }
  set editMode(value: BooleanInput) {
    this._editMode = coerceBooleanProperty(value);
  }
  private _editMode = true;

  @Input() count = 0;
  @Input() genericEntityName: [string, string] = ['Ergebnis', '-se'];

  @Output() readonly searched = new EventEmitter<string>();

  onSearchTermEmitted(searchTerm: string): void {
    const nextValue = searchTerm.trim().length > 0 ? searchTerm : '';
    this.searched.emit(nextValue);
  }
}
