<mpctm-page-footer>
  <div class="footer">
    <span class="footer__label font-bold" title="Gezeichnet von">Gezeichnet</span> von

    <span class="footer__value font-bold truncate" [title]="signedBy()">
      {{ signedBy() }}
    </span>
    am
    <span
      class="footer__value font-bold truncate"
      *ngrxLet="dateOfSigning() | date: 'dd.MM.yyyy' as dateOfSigning"
      [title]="dateOfSigning"
    >
      {{ dateOfSigning }}.
    </span>
  </div>
</mpctm-page-footer>
