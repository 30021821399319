import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { ConditionGraduations } from '@mp/contract-management/condition-details/tab-graduations/domain';

import { ConditionInfoSectionComponent } from '../../condition-info-section/condition-info-section.component';

import { ConditionGraduationsComponent } from './condition-graduations/condition-graduations.component';

@Component({
  selector: 'mpctm-condition-graduations-section',
  standalone: true,
  templateUrl: './condition-graduations-section.component.html',
  styleUrl: './condition-graduations-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ConditionInfoSectionComponent, ConditionGraduationsComponent],
})
export class ConditionGraduationsSectionComponent {
  readonly conditionGraduations: InputSignal<ConditionGraduations | null> =
    input.required<ConditionGraduations | null>();

  protected readonly graduationsCount: Signal<number> = computed(() => {
    // TODO - verify the graduations count computation
    return (
      (this.conditionGraduations()?.graduations?.length ?? 0) + (this.conditionGraduations()?.baseCondition ? 1 : 0)
    );
  });
}
