import { inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { type CategoryType } from '@core/shared/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type CategoriesExportPlugin } from '@mpcm/shared';
import { LoginContextService } from '@mpk/shared/data-access';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { CategoriesService } from '@mp/content-manager/categories/data-access';

import { CategoriesPermissions } from '../permissions';

/**
 * Factory to create the Excel categories export plugin.
 */
export function createExcelExportCategoriesPlugin(): CategoriesExportPlugin {
  const loginContextService = inject(LoginContextService);
  const categoriesService = inject(CategoriesService);

  return {
    displayName: 'Excel Warengruppen-Export',
    description: 'Exportiert die Warengruppen in eine Excel-Datei',
    isEnabled: loginContextService.permission(CategoriesPermissions.ViewAndExportCategories),
    execute(categoryType: CategoryType, version: string): Promise<void> {
      return lastValueFrom(categoriesService.exportCategoriesExcel(categoryType, version));
    },
  };
}
