import { Injector, type Signal, assertInInjectionContext, computed, inject } from '@angular/core';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { lastValueFrom } from 'rxjs';

import type { CategoryNode } from '@mp/content-manager/categories/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { CategoryClassification } from '@mpcm/shared';

import { CategoriesService } from '../services';

/**
 * Leaf node of the category tree.
 */
interface CategoryQueryNodeSpecific extends CategoryNode {
  readonly isMostSpecific: true;
}

/**
 * Node of the category tree that has child nodes.
 */
interface CategoryQueryNodeParent extends CategoryNode {
  readonly isMostSpecific: false;
  readonly children: ReturnType<typeof injectQuery<CategoryQueryNode[]>> | null;
}

/**
 * Describes a node of the category tree.
 */
export type CategoryQueryNode = CategoryQueryNodeParent | CategoryQueryNodeSpecific;

export function injectCategoryNodesQuery(categoryClassification: Signal<CategoryClassification>, injector?: Injector) {
  if (!injector) {
    assertInInjectionContext(injectCategoryNodesQuery);
    injector = inject(Injector);
  }

  const categriesService = injector.get(CategoriesService);

  function factory(
    classification: CategoryClassification,
    code: string,
  ): ReturnType<typeof injectQuery<CategoryNode[], Error, CategoryQueryNode[]>> {
    return injectQuery(() => {
      const { type, version } = classification;

      return {
        queryKey: ['amor3', 'export', 'categories', type, version, code],
        queryFn: () => lastValueFrom(categriesService.fetchCategoryNodes(type, version, code)),
        refetchOnWindowFocus: false,
        initialData: code ? ([] as CategoryNode[]) : undefined,
        staleTime: ({ state }) => (code && state.dataUpdateCount === 0 ? 0 : 600_000),
        select: (nodes) =>
          nodes.map<CategoryQueryNode>(({ code, description, isMostSpecific }) => {
            let children: CategoryQueryNodeParent['children'] = null;
            return {
              code,
              description,
              isMostSpecific,
              // lazy query initialization, otherwise it will load immediately
              get children() {
                return isMostSpecific ? null : (children ??= factory(classification, code));
              },
            };
          }),
      };
    }, injector);
  }

  return computed(() => {
    return factory(categoryClassification(), '');
  });
}
