import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule, TitleStrategy } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import 'hammerjs';
import { QuillModule } from 'ngx-quill';

import { RouterFacade, provideQueryClient } from '@core/shared/data-access';
import {
  AbstractThemeChangesTrackingService,
  GlobalErrorHandlerService,
  IconRegistryModule,
  ThemeChangesTrackingDevService,
} from '@core/shared/util';
import { provideExportFeature } from '@mp/content-manager/exports/feature';
import { NewsPermissions } from '@mp/dashboard/newsfeed/feature';
import { AppInsightsFeatureModule } from '@mp/shared/app-insights/feature';
import { InsightsOrganizationInfoService } from '@mp/shared/app-insights/util';
import { HttpErrorInterceptor, PlatformRouterSerializer, SharedModule } from '@mp/shared/data-access';
import { JobServerPermissions } from '@mp/shared/feature';
import { AbstractLinkProviderService, provideModuleInfo } from '@mp/shared/util';
import { DeploymentSettingsPermissions } from '@mp/system/deployment-settings/feature';
import { PlatformPackagingUnitsPermissions } from '@mp/system/platform-packaging-units/feature';
import { ActiveOrganizationInterceptor, provideProfileDataAccess } from '@mpauth/shared/data-access';
import { provideBusinessIntelligenceFeature } from '@mpbi/feature';
import { provideContentManagerFeature } from '@mpcm/feature';
import { provideContractManagementFeature } from '@mpctm/feature';
import { provideDocumentManagementFeature } from '@mpdm/feature';
import { provideAmor3Feature } from '@mpia/feature';
import { provideGhxFeature } from '@mpig/feature';
import { provideMediioFeature } from '@mpim/feature';
import { provideSapOdataFeature } from '@mpiso/feature';
import { provideKernelFeature } from '@mpk/feature';
import { AbstractAuthInfoProviderService, provideModulePermissions } from '@mpk/shared/data-access';
import { provideMedicalShopFeature } from '@mpms/feature';
import { provideOrganizationMasterDataFeature } from '@mpomd/feature';
import { provideSupplierManagementFeature } from '@mpsm/feature';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { REDUCERS_TOKEN, reducerProvider } from './app.reducer';
import { routes } from './app.routes';
import { AppInsightsOrganizationInfoService, AuthInfoProviderService } from './auth';
import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from './date-adapter';
import { AppTitleStrategy } from './info/app-title-strategy';
import { appInitProvider } from './init/app-init.service';
import { LinkProviderService } from './navigation';

registerLocaleData(localeDe, 'de', localeDeExtra);

@NgModule({
  imports: [
    SharedModule,
    AppInsightsFeatureModule,
    BrowserModule,
    MatNativeDateModule,
    RouterModule.forRoot(routes),
    StoreRouterConnectingModule.forRoot({ serializer: PlatformRouterSerializer }),
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    MatSnackBarModule,
    HttpClientModule,
    IconRegistryModule,
    QuillModule.forRoot(),
  ],
  providers: [
    provideProfileDataAccess(),
    appInitProvider,
    reducerProvider,
    provideAnimationsAsync(),
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveOrganizationInterceptor,
      multi: true,
    },
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: AbstractAuthInfoProviderService, useClass: AuthInfoProviderService },
    RouterFacade,

    !environment.production
      ? { provide: AbstractThemeChangesTrackingService, useClass: ThemeChangesTrackingDevService }
      : [],
    { provide: AbstractLinkProviderService, useClass: LinkProviderService },
    { provide: InsightsOrganizationInfoService, useClass: AppInsightsOrganizationInfoService },
    provideQueryClient(),
    provideKernelFeature(),
    provideOrganizationMasterDataFeature(),
    provideBusinessIntelligenceFeature(),
    provideContentManagerFeature(),
    provideSupplierManagementFeature(),
    provideMedicalShopFeature(),
    provideContractManagementFeature(),
    provideDocumentManagementFeature(),
    provideExportFeature(),
    provideModuleInfo('Jobs', {
      displayName: 'Jobs',
    }),
    provideModulePermissions('Jobs', JobServerPermissions),
    provideModulePermissions('Kernel', NewsPermissions, DeploymentSettingsPermissions),
    provideModulePermissions('ContentManager', PlatformPackagingUnitsPermissions),
    provideAmor3Feature(),
    provideSapOdataFeature(),
    provideGhxFeature(),
    provideMediioFeature(),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
