import { AbstractControl, type FormGroup, ValidationErrors } from '@angular/forms';

/**
 * Sets the given errors as `server` validation errors on the given group.
 * @param formGroup The form group to set the errors to.
 * @param errors The errors from the server validation. Contains errors per property path.
 * @param setDeepErrorsOnParent Defines, if path-segment-wise errors are set on parent controls:
 *
 *   - `never`: Keys of `errors` are never evaluated segment-wise. Only `server` errors are set on controls
 *              that are found. Errors without a corresponding control are ignored.
 *   - `always`: `errors` keys are always evaluated segment-wise. `server` errors are set on controls
 *              that are found. Additionally errors of sub properties with the sub path as error key are set on each control.
 *   - `nonexistent`: `errors` are evaluated segment-wise only if there is not a corresponding control. The error
 *                    of the property is set to the closest found parent with the corresponding sub path as error key.
 */
export function setServerValidationErrors(
  formGroup: FormGroup,
  errors: Record<string, string[]>,
  setDeepErrorsOnParent: 'never' | 'always' | 'nonexistent' = 'never',
): void {
  for (const [ctrl, messages] of getControlErrors(formGroup, errors, setDeepErrorsOnParent)) {
    ctrl.setErrors(messages);
  }
}

// eslint-disable-next-line complexity
function getControlErrors(
  formGroup: FormGroup,
  errors: Record<string, string[]>,
  setDeepErrorsOnParent: 'never' | 'always' | 'nonexistent' = 'never',
): Iterable<[ctrl: AbstractControl, errors: ValidationErrors]> {
  const errorMap = new Map<AbstractControl, ValidationErrors>();

  for (const [field, messages] of Object.entries(errors)) {
    const ctrl = formGroup.get(field);

    if (ctrl) {
      const errors = errorMap.get(ctrl) ?? {};

      errorMap.set(ctrl, {
        ...errors,
        server: {
          msg: messages[0],
        },
      });
    } else if (setDeepErrorsOnParent === 'never') {
      continue;
    }

    if (!ctrl || setDeepErrorsOnParent === 'always') {
      for (const [controlPath, subPath] of getFieldSegments(field)) {
        const control = controlPath ? formGroup.get(controlPath) : formGroup;

        if (control) {
          const errors = errorMap.get(control) ?? {};

          errorMap.set(control, {
            ...errors,
            [subPath]: {
              msg: messages[0],
            },
          });

          if (setDeepErrorsOnParent !== 'always') {
            break;
          }
        }
      }
    }
  }

  return errorMap.entries();
}

function* getFieldSegments(path: string): Iterable<[controlPath: string, subPath: string]> {
  let pos = path.length;

  do {
    pos = path.lastIndexOf('.', pos - 1);
    yield [path.substring(0, pos), path.substring(pos + 1)];
  } while (pos !== -1);
}
