<mpctm-page-footer>
  <mpctm-selected-graduation
    class="selected-graduation truncate"
    [selectedGraduationLabel]="selectedGraduationLabel()"
    [validUntil]="validUntil()"
  />

  <div class="condition-additional-info">
    @if (requireAdditionalInfo()) {
      <mpctm-condition-warning-message>
        <span class="truncate" [title]="infoText">{{ infoText }}</span>
      </mpctm-condition-warning-message>

      <mpctm-condition-action-button
        color="warn"
        label="Bestätigung anfordern"
        icon="draw"
        (click)="requestConfirmation.emit()"
      />
    } @else {
      <mpctm-condition-action-button
        color="accent"
        label="Zeichne Auswahl"
        icon="draw"
        (click)="signCondition.emit()"
      />
    }
  </div>
</mpctm-page-footer>
