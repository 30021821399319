import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConditionSigningDetailsState } from '../models';

import { conditionSigningDetailsFeatureKey } from './condition-signing-details.reducer';

export const selectConditionSigningDetailsState = createFeatureSelector<ConditionSigningDetailsState>(
  conditionSigningDetailsFeatureKey,
);

export const selectConditionDetailsLoaded = createSelector(
  selectConditionSigningDetailsState,
  (state) => state.conditionSigningDetailsLoaded,
);

export const selectConditionSigningDetails = createSelector(
  selectConditionSigningDetailsState,
  (state) => state.conditionSigningDetails,
);
